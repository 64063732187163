import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

//old 
// const firebaseConfig = {
//   apiKey: "AIzaSyDrRBzYzFXt4apH_xxhcVOdYIxyj8qiShQ",
//   authDomain: "sarc-f02a3.firebaseapp.com",
//   projectId: "sarc-f02a3",
//   storageBucket: "sarc-f02a3.appspot.com",
//   messagingSenderId: "535149316399",
//   appId: "1:535149316399:web:0e4321658e8d015c6979da"
// };


//new 
const firebaseConfig = {
  apiKey: "AIzaSyA9yK2SXeuRN5wmVeuqBV3EjvGQ1rtS3ik",
  authDomain: "sarc-kaauh.firebaseapp.com",
  projectId: "sarc-kaauh",
  storageBucket: "sarc-kaauh.appspot.com",
  messagingSenderId: "212029294504",
  appId: "1:212029294504:web:43201b6a50ddabf2637f9d"
};
// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const storage = firebase.storage();
const auth = firebase.auth();

export { auth, db,storage };