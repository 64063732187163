import React,{ useState} from 'react'
import { Header } from '../Components/Header'
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/userContext'

const Choose = () => {
  const {userDetails} = useUserAuth();
  const navigate = useNavigate();
const [valueOfUser,setValueOfUser]=useState("")
  const changeChoose =(value)=>{
    if(value === 'attendee'){
      const item = document.getElementById("attendee");
      const all = document.querySelectorAll(".choose-part")
      all.forEach((item) =>{
        item.classList.remove('active')
      })
      item.classList.add('active')
      setValueOfUser('attendee');
      // console.log(item);
    }
    else if(value === 'speaker'){
      const item = document.getElementById("speaker");
      const all = document.querySelectorAll(".choose-part")
      all.forEach((item) =>{
        item.classList.remove('active')
      })
      item.classList.add('active')
      setValueOfUser('speaker');
      // console.log(item);
    }
    else return;
  }
  const onClickNext =()=>{
    if(!valueOfUser) return;
    if(valueOfUser === 'attendee') navigate('/sarc3/attendee');
    else navigate('/sarc3/speaker');
  }
  return (
    <div className ='choose-page'>
        <div className='container'>
            <Header smallNav={<div className='user-icon-sm'>
                {/* <p>{userDetails && userDetails?.name?.slice(0,2)}</p> */}
              </div>}>
              {/* <div className='user-icon'>
                <p>{userDetails && userDetails?.name?.slice(0,2)}</p>
              </div> */}
              
            </Header>
        <div className='join'>
        <div className="row">
          <div className="col-sm-12 col-md-6 mb-1 bg-none">
            <img src='/assets/p1n.png' alt='partnership' className="img-fluid"style={{minWidth:'320px'}} />
          </div>
          <div className="col-sm-12 col-md-6 mb-1">
            <img src='/assets/p2n.png' alt='partnership' className="img-fluid" style={{minWidth:'350px'}}/>
          </div>
        </div>
        <img src='/assets/auth-sarc.webp' alt='auth'/>
        <p className='join-head'>Join Us!</p>
        <p className='join-body'>To begin this journey, tell us what type of account you’d be opening.</p>
        <div className='choose-part' id="attendee" onClick={() => changeChoose('attendee')}>
          <div className='shape'>
         <div className='shape-inside'>
         <i className="fa-regular fa-user"></i>
         </div>
          </div>
          <div className='paraghs'>
            <h3 className="fw-bold ">Register as attendee</h3>
            {/* <p>Personal account to manage all you activities.</p> */}
          </div>
          <div className='icon'><i className="fa-solid fa-arrow-right"></i></div>
        </div> 
        {/* <div className='not-choose-part' id="attendee" style={{cursor:'not-allowed'}}>
          <div className='not-shape'>
         <div className='not-shape-inside'>
         <i className="fa-regular fa-user"></i>
         </div>
          </div>
          <div className='paraghs'>
            <p>attendee    (Soon...)</p>
            <p>Personal account to manage all you activities.</p>
          </div>
          <div className='icon'><i className="fa-solid fa-arrow-right"></i></div>
        </div>  */}
        {/* <div className='choose-part' id="speaker" onClick={() => changeChoose('speaker')}>
          <div className='shape'>
          <div className='shape-inside'>
          <i className="fa-solid fa-microphone"></i>
          </div>
          </div>
          <div className='paraghs'>
            <p>speaker.</p>
            <p>Own or belong to a company, this is for you.</p>
          </div>
          <div className='icon'><i className="fa-solid fa-arrow-right"></i></div>
        </div> */}
         <div className='not-choose-part' id="speaker" style={{cursor:'not-allowed'}}>
          <div className='not-shape'>
         <div className='not-shape-inside'>
         <i className="fa-solid fa-microphone"></i>
         </div>
          </div>
          <div className='paraghs'>
            <h3  className="fw-bold ">speaker</h3>
            {/* <p>Own or belong to a company, this is for you.</p> */}
          </div>
          <div className='icon'><i className="fa-solid fa-arrow-right"></i></div>
        </div>
        <form>
        <button disabled={!valueOfUser? true : false} onClick={() =>onClickNext()}>Next</button>
        </form>
      </div>
        </div>
    </div>
  )
}

export default Choose