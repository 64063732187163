import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userContext";
const AuthRoute = ({ children }) => {
  const { user } = useUserAuth();

  if (!user) {
      return children;
}
return <Navigate to="/" />;
};

export default AuthRoute;