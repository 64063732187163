// Workshops.js
import React from 'react';
import '../css/workshops.css'; // Make sure the path to your CSS file is correct

const WorkshopsComponent = () => {
  // Assuming the content is static and based on the provided schedule
  const morningWorkshops = [
    {
      id: 1,
      time: '10:00 - 12:00',
      title: 'Using Implementation Science in Early Intervention',
      speaker: 'Dr. Mayada Elsabbagh (K)'
    },
    {
      id: 2,
      time: '10:00 - 12:00',
      title: 'Biological Treatment of Autism: Medications, ECT and Novel Interventions',
      speaker: 'Prof. Gaziudin (K)'
    },
    {
      id: 3,
      time: '10:00 - 12:00',
      title: 'Psychopharmacology: Clinical and Research Update on the Psychopharmacological Treatment of Autism',
      speaker: 'Dr. Antonio Hardan (K)'
    }
  ];

  const afternoonWorkshops = [
    {
      id: 4,
      time: '13:00 - 15:00',
      title: 'Safeguarding individuals with autism: Programming for safety and independence skills',
      speaker: 'Dr. Asmahan Saleh Khalil (K)'
    },
    {
      id: 5,
      time: '13:00 - 15:00',
      title: 'Navigating Autism: Diagnosis, Comorbidities, and Strategies for Success',
      speaker: 'Prof. Gaziudin (K)'
    },
    {
      id: 6,
      time: '13:00 - 15:00',
      title: 'ABA-based training on personal hygiene and protection against sexual abuse for Autistic Individuals: Promoting Healthy Development',
      speaker: 'Ms. Abeer Alshareef sayedahemd (A) & Dr. Amel Alawami (S)'
    }
  ];

  return (
    <div className="workshops">
      <h2 className="session-header">Workshops Morning</h2>
      <div className="workshop-session">
        {morningWorkshops.map(workshop => (
          <div key={workshop.id} className="workshop-card">
            <div className="workshop-time">{workshop.time}</div>
            <div className="workshop-info">
              <div className="workshop-title">{workshop.title}</div>
              <div className="workshop-speaker">{workshop.speaker}</div>
            </div>
          </div>
        ))}
      </div>

      <h2 className="session-header">Workshops Afternoon</h2>
      <div className="workshop-session">
        {afternoonWorkshops.map(workshop => (
          <div key={workshop.id} className="workshop-card"  >
            <div className="workshop-time">{workshop.time}</div>
            <div className="workshop-info">
              <div className="workshop-title text-center">{workshop.title}</div>
              <div className="workshop-speaker">{workshop.speaker}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkshopsComponent;
