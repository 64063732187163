import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Table, Form, Button } from 'react-bootstrap';
import { useUserAuth } from '../context/userContext';
import { Header } from '../Components/Header';
import { useNavigate } from "react-router-dom";
import AddUserModal from '../Components/AddUserModal';
function AllUsers() {
    const [users, setUsers] = useState([]); // State to store users data
    const [searchTerm, setSearchTerm] = useState(''); // State to store search term
    const [currentPage, setCurrentPage] = useState(1); // State to store current page number
    const [totalPages, setTotalPages] = useState(1); // State to store total number of pages
    const { user } = useUserAuth(); // Get user from context
    const navigate = useNavigate();

        const[userModalOpen,setUserModalOpen]=useState(false)
    useEffect(() => {
        // Fetch users data when component mounts
        fetchUsers();
    }, [searchTerm, currentPage]); // Fetch users whenever searchTerm or currentPage changes

    // Function to fetch users data
    const fetchUsers = async () => {
        try {
            let url = `https://sarcbackend-8f58e8101861.herokuapp.com/allUsers?page=${currentPage}`;

            if (searchTerm.trim() !== '') {
                url = `https://sarcbackend-8f58e8101861.herokuapp.com/allUserswithoutpagination?email=${searchTerm}`;
            }

            const response = await axios.get(url, {
                headers: {
                    "auth": user.accessToken
                }
            });

            if (searchTerm.trim() === '') {
                setUsers(response.data.users); // Set users data
                setTotalPages(response.data.totalPages);
            } else {
                setUsers(response.data.filter(user => user.email.includes(searchTerm))); // Filter users by email
                setTotalPages(1); // Set total pages to 1 as only one page will be displayed
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Function to handle search term change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value); // Update search term
        setCurrentPage(1); // Reset currentPage to 1 when searchTerm changes
    };

    // Function to handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };
    const handleEdit = (email) => {
        // fetchUserApplications()
        navigate(`/sarc3/attendee/edit/${email}`);
      };
      const handleOpenModal = () => {
        setUserModalOpen(true);
    };
   // Function to handle closing modal
  
    // Function to handle closing modal
   
    return (
        <Container>
            <Header/>
            <h1 className="text-center mt-4 mb-4">All Users</h1>
            <Form className="mb-3">
                <div className="row">
                    <div className="col-md-10">
                    <Form.Group controlId="searchTerm">
                    <Form.Control
                        type="text"
                        placeholder="Search by email"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                  
                </Form.Group>
                    </div>
                    <div className="col-md-2">
                    <div className="mb-3 d-flex justify-content-end">
                <Button variant="primary" onClick={handleOpenModal}>Add New User</Button>
            </div>
                    </div>
                </div>
                
            </Form>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        {/* <th>serial number</th> */}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>SCFHSNo</th>
                        <th>Role</th>
                        <th>Action</th>
                        {/* Add more columns as needed */}
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user._id}>
                            {/* <td>{user._id}</td> */}
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.SCFHSNo}</td>
                            <td>{user.role}</td>
                            <td>
                  <Button
                    variant="warning"
                    onClick={() => handleEdit(user.email)}
                  >
                    Edit
                  </Button>
                </td>
                            {/* Add more columns as needed */}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {/* Pagination */}
            {searchTerm.trim() === '' && (
                <div className="pagination container">
                    <Button
                        className="w-50"
                        variant="primary"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <span className="mx-3">Page {currentPage} of {totalPages}</span>
                    <Button
                        variant="primary"
                        className="w-50"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            )}
            <AddUserModal userModalOpen={userModalOpen}  handleOpenModal={handleOpenModal} setUserModalOpen={setUserModalOpen}  
  
  fetchUsers={fetchUsers}
       />
        </Container>
    );
}

export default AllUsers;
