import React from 'react'

export const Footer = () => {
  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 col-sm-6'>
            <div className='information'>
              <div className='section'>
              <p className='title'>Address:</p>
              <p className='value'>king abdullah bin abdulaziz </p>
              <p className='value'>university hospital</p>
              </div>
              <div className='section'>
              <p className='title'>Email:</p>
              <span className='email'>SARC@KAAUH.edu.sa</span>
              </div>
              <div className='section'>
              <p className='title'>Phone:</p>
              <p className='value'>920035900</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 col-sm-6'>
            <div className='subscribe'>
              <p className='signup'>Sign up to get our Updates ↓</p>
              <div className='signup-btn'>
              <input type='text'/>
              <button type='send'>Subscribe</button>
              </div>
              <p className="desc">Will send you weekly updates for your better</p>
              <p className="desc">tool management.</p>
            </div>
          </div>
        </div>
        <div className='line'></div>
        <div className='second-part'>
          <div className='social'>
            <span>Follow us on:</span>
            <ul>
              <li><img alt='twitter' src='/assets/twitter.svg'/></li>
              <li><img alt='whats' src='/assets/whats.svg'/></li>
              <li><img alt='linkedin' src='/assets/linkedin.svg'/></li>
            </ul>
          </div>
          <div className='terms'>
            <p>Terrms and condition</p>
            <p>Privacy policy</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
