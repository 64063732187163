import axios from 'axios'
import React, {useEffect, useState } from 'react'
import { useUserAuth } from '../context/userContext'
import { Link } from 'react-router-dom';
import Members from '../Components/Members';
import {CSVLink} from 'react-csv'
const Admin = ({phrase,nameClass}) => {
    const {user} = useUserAuth();

    const [filter,setFilter] = useState('');

    const [filtered,setFiltered] = useState([]);
    const [search,setSearch] = useState('');
    const [filteredCount, setFilteredCount] = useState(0);
    const [seacrhedCount, setSearchedCount] = useState(0);
    const [title, setTitle] = useState('all members');
    const [disableBtn, setDisableBtn] = useState(false);
    // const [display, setDisplay] = useState(true);
    const [itemsNo, setItemsNo] = useState(8);
    const [data,setData] = useState([]);
useEffect(() =>{
    fetchAllMembers()
},[])
    useEffect(() =>{
        if(filter) filteredMembers();
    },[filter,filteredCount])

    const fetchAllMembers =async()=>{

        await axios.get('https://sarcbackend-8f58e8101861.herokuapp.com/allMembers',{
            headers:{
                "auth": user.accessToken,
            }
        }).then(res=>{
            console.log(res,'members');
            // const data = await res.json();
            // setFiltered([])
            if(res.data && res.data.length === 0) setDisableBtn(true)
            setData([...res.data])
        })
        .catch(err => {
            console.log(err);
            console.log(err.response.status,'status');
            if(err.response.status === 453){
                let refresh = window.location.href;
                window.location.href = refresh;
            }
        })
        }
        const filteredMembers =async()=>{
            // console.log(filter ,'filter value');
            // console.log('start filter');
            // console.log(filteredCount,itemsNo);
            await axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/filteredMembers',{filter},{

                headers:{
                    "auth": user.accessToken,
                    "itemsNo": itemsNo,
                    "skip": (filteredCount*itemsNo)
                }
            }).then(res =>{
                console.log(res,'res filtered data');
                // const data = await res.json();
                // setMembers([]);
                if(res.data && res.data.length === 0) setDisableBtn(true)
                setFiltered(prev=>[...prev,...res.data])
            }).catch(error => {
                console.log(error,'/error');
                console.log(error.response.status,'status');
            if(error.response.status === 453){
                let refresh = window.location.href;
                window.location.href = refresh;
            }
            })
          
    
            }
    const handleSearch =async(e)=>{
        // setDisplay(false)
        e.preventDefault();
        console.log('start search',seacrhedCount,'searchedCount');
        await axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/filteredMembers',{search},{
            headers:{
                "auth": user.accessToken,
                "itemsNo": itemsNo,
                "skip": (seacrhedCount*itemsNo)
            }
        }).then(res =>{
            console.log(res,'res searched applications');
            // const data = await res.json();
            // setMembers([]);
            if(res.data && res.data.length === 0) setDisableBtn(true)
            setFiltered(prev=>[...prev,...res.data])
        }).catch(error => {
            console.log(error,'/error');
            console.log(error.response.status,'status');
            if(error.response.status === 453){
                let refresh = window.location.href;
                window.location.href = refresh;
            }
        })
      

        }
        const handleNavsMember=(member) =>{
            setDisableBtn(false)
            setSearch('')
            setFiltered([]);
            const all = document.querySelectorAll('.handle-nav');
            const one = document.getElementById(member);
            // console.log(one,'one');
            all.forEach(element => {
                element.classList.remove('active') 
            });
            one.classList.add('active')
            if(member ==='All members'){
                // setDisplay(true)
                setTitle('all members');
                return;
            }
            // setMembers([])
            if(member === 'speaker') setTitle('Only Speakers'); setFilteredCount(0); 
            if(member === 'attendee') setTitle('Only Attendees');setFilteredCount(0); 
            if(member === 'in progress') setTitle('In Progress');setFilteredCount(0); 
            if(member === 'approved') setTitle('Approved');setFilteredCount(0); 
            if(member === 'rejected') setTitle('Rejected');setFilteredCount(0); 

            setFilter(member);
        }
        // const handleLoad=()=>{
        //     setCount(count => 1 + count);
        //     console.log(count ,' load btn');
        //     fetchAllMembers(count)
        // }
        const onChangeSearch =(e)=>{
            setDisableBtn(false)
            setFiltered([])
            setTitle('search')
            setSearch(e.target.value);
            setSearchedCount(0)
        }
        const handleMoreSearch =(e) =>{
            setSearchedCount(seacrhedCount + 1);
            handleSearch(e);
        }
  return (
    <div className='dashboard'>
        <div className='fill'></div>
        <div className='title'>
            <div className='container'>
                <div className='manage-p-btn'>
                <p className={nameClass? nameClass : ''}>{phrase}</p>
                <button><img src='/assets/cloud.svg' alt='cloud'/><CSVLink data={data} filename='Applications'> Export as Excel sheet</CSVLink></button>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='navigation'>
                <ul>
                    <li id = 'All members' className='handle-nav active'onClick={() => handleNavsMember('All members')}>All members</li>
                    <li id = 'attendee' className='handle-nav' onClick={() => handleNavsMember('attendee')}>attendee</li>
                    <li id = 'speaker' className='handle-nav' onClick={() => handleNavsMember('speaker')}>speakers</li>
                    <li id = 'in progress' className='handle-nav' onClick={() => handleNavsMember('in progress')}>in progress</li>
                    <li id = 'approved' className='handle-nav' onClick={() => handleNavsMember('approved')}>approved</li>
                    <li id = 'rejected' className='handle-nav' onClick={() => handleNavsMember('rejected')}>rejected</li>
                </ul>
            </div>
            <div className='search'>
                <div className='content'>
                    <p>{title}</p>
                    <p>Keep track of all member requests</p>
                </div>
                <div className='filter'>
                        <img src='/assets/Search.svg' style={{cursor:'pointer'}} alt='saecrh' onClick={(e) => handleMoreSearch(e)}/>
                        {/* <form onSubmit={handleSearch}> */}
                        <input type='text' placeholder='Search' value={search} onChange={e=>onChangeSearch(e)}/>
                        {/* </form> */}
                </div>
            </div>
            { title === 'all members'  ? <Members data={data} setData={setData}/>  : 
            <table className='table-users'>
                <thead>
                    <tr>
                    <th>Full Name</th>
                    <th>Application status</th>
                    <th>Phone number</th>
                    <th>Email address</th>
                    <th>Attendee/speaker</th>
                    <th className='view'>Application</th>
                    </tr>
                </thead>
             <tbody>
                    {filtered.map((mem,ind) =>
                    <tr key={ind}>
                    <td className='name'>{mem.name}</td>
                    <td><p className={`status ${mem.AppStatus && mem.AppStatus === 'Approved' ? 'green':mem.AppStatus === 'Rejected' ? 'red' : ''}`}>{mem.AppStatus}</p></td>
                    <td>+ {mem.phone}</td>
                    <td className='email'>{mem.email}</td>
                    <td className='type'>{mem.participateAs}</td>
                    <td className='view'><Link to={`/sarc3/manage/${mem._id.toString()}`}>View</Link></td>
                </tr>
                    )}
                    <tr className='row-btn'>
                     {title === 'search' ? 
                        <td colSpan='6'><button disabled={disableBtn ? true:false} onClick={(e) => handleMoreSearch(e)}>Load More</button></td>
                        :<td colSpan='6'><button disabled={disableBtn ? true:false} onClick={() => setFilteredCount(filteredCount + 1)}>Load More</button></td> 
                    }

                    </tr>
                </tbody>
            </table>}
            
        </div>
        <div className='foot-page'>
            <p>© 2023<span className='dark'>SA</span><span className='light'>RC</span>Admin All Rights Reserved</p>
        </div>
    </div>
  )
}

export default Admin