import React, { useState } from 'react'
import { Header } from '../Components/Header'
import { useUserAuth } from "../context/userContext";
import { Error } from '../Components/Error';
import { useNavigate } from 'react-router-dom';

const Forgot = () => {
  const navigate = useNavigate();
  const { forgotPassword} = useUserAuth();
    const [email,setEmail]=useState("");
    const [error,setError]=useState("");
    const [message,setMessage]=useState("");
    const handleSubmit = async(event) => {

      event.preventDefault()
      // setIsPending(true);
      if(!email) {
        setError('Email is required.'); 
          setTimeout(() => {setError("")}, 3000); return;
        }
      try{
          await forgotPassword(email);
          setMessage('We Send Reset Password URL to Your Email.');
          setTimeout(() =>{
            setMessage('')
          },4000)
          // navigate("/");
        }catch(e) {
          console.log(e,'e');
          switch(e.code){
            case 'auth/user-not-found':
                setError('There is no matched email.');
                break;
            default: 
                setError(e.code);
        }
      };
  }
  return (
    <div className="container">
    <Header smallNav={<>
      <p style={{marginBottom:'10px'}}>have account?</p>
      <button className='logout-btn' onClick={() => navigate('/login')}>Login</button></>
    }>
      <div className='register'> 
      <p>have account?</p>
      <button onClick={() => navigate('/login')}>Login</button>
      </div>
      </Header>
      <div className='login'>
        <img src='/assets/auth-sarc.webp' alt='login'/>
        <p className='login-head'>Reset Password</p>
        <p className='login-body'>For Reset Password, we need the Email</p>
        {error && <Error msg={error}/>}
        {message && <Error msg={message} className='message-msg'/>}
        <form onSubmit={handleSubmit}>
        <div className="div-input">
              <label htmlFor='email'>Email address</label>
              <input id="email" value={email} onChange={e=>setEmail(e.target.value)} type="text"/>
        </div>
        <button>Submit</button>
        </form>
      </div>

  </div>
  )
}

export default Forgot