import React from 'react'
import { Scientific } from '../Components/Scientific'
import { OutcomeGoals } from '../Components/OutcomeGoals'
import { Header } from '../Components/Header'
import { Banner } from '../Components/Banner'
import { Soon } from '../Components/Soon'
import { Themes } from '../Components/Themes'
import { Objectives } from '../Components/Objectives'
import { Contact } from '../Components/Contact'
import { Footer } from '../Components/Footer'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Workshops from './WorkshopsComonent'
import NewsTicker from './NewsTiker'
import '../css/sarc3.css'
import Sponsers from './sponsers'
import Partner from './Partner'

const Sarc3 = () => {
  const navigate=useNavigate()
  useEffect(() =>{
window.scroll(0,0);
  },[])
  return (
    <div className='home sarc3 bg-light'>
      {/* <div className='new-application' onClick={() =>navigate('/sarc3/registerApplication')}>
      <i className="fa-solid fa-puzzle-piece"></i>
      </div> */}
    <div className="container ">
    <Header/>
    <Banner/>
    <Soon/>
    <Partner/>
    {/* <Workshops/> */}
    <Themes/>
    <Objectives/>
    <OutcomeGoals/>

    <Sponsers/>
    <Scientific/>
    <Contact/>
    
   </div>
 
   <Footer/>
   {/* <NewsTicker/> */}
   </div>
  )
}

export default Sarc3