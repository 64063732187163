import React from 'react'
import ReactDom from 'react-dom'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '24px',
  zIndex: 1000,
  borderRadius:'8px',
  textAlign:'center'
}
const BTN_DONT ={
    width:'100%',
    marginBottom:'16px',
    fontSize:'18px',
    fontWeight:500,
    background:'none',
    borderRadius:'8px'
}
const BTN_CANCEL ={
    width:'100%',
    marginBottom:'16px',
    fontSize:'18px',
    fontWeight:500,
    background:'none',
    borderRadius:'8px',
    padding:'8px',
    border:'1px solid #B4B4B4'
}
const SAVE = {
    color:"#fff",
    background:"#C1D34C",
    width:'100%',
    marginBottom:'16px',
    fontSize:'18px',
    fontWeight:500,
    borderRadius:'8px',
    padding:'8px'
}
const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000
}

export default function Modal({ open, onClose,children }) {
  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <div style={MODAL_STYLES}>
      {children}
      </div>
    </>,
    document.getElementById('portal')
  )
}