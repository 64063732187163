import React, { useState } from 'react'
import { Header } from '../Components/Header'
import { Error } from '../Components/Error'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate} from 'react-router-dom'
import axios from 'axios';
import { useUserAuth } from '../context/userContext';
import '../css/register.css'
const Register = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeWorkshopTab, setActiveWorkshopTab] = useState('morning'); // New state for morning/afternoon workshop tabs

  const {setUser,setUserDetails,userDetails,setIsAdmin} = useUserAuth();
  const navigate = useNavigate();
    const [fullName,setFullName]=useState("");
    const [password,setPassword]=useState("");
    const [email,setEmail]=useState("");
    const [phone,setPhone]=useState("");
    const [parc,setParc]=useState("");
    const [SCFHSNo,setSCFHSNo]=useState("");
    const [error,setError] = useState("")
    const [selectedConferenceSessions, setSelectedConferenceSessions] = useState([]);
    const [activeTab, setActiveTab] = useState('workshops'); // Default to workshops

    const [workshop, setWorkshop] = useState([]);
    const handleChange = (e) => {
      const { value, checked } = e.target;
      if (checked && !workshop.includes(value)) {
        setWorkshop([...workshop, value]);
      } else {
        setWorkshop(workshop.filter(item => item !== value));
      }
    };
    


    const conferenceSchedule = [
      {
        day: "DAY 1",
        date: "Tuesday 23rd April 2024",
        sessions: [
          {
            sessionNum: "Session (1)",
            moderator: "Moderator: Dr. Faisal Alnemry (S)",
            events: [
              {
                time: "8:00",
                title: "Registration",
                speaker: ""
              },
              {
                time: "8:30 - 9:00",
                title: "Opening Ceremony",
                speaker: ""
              },
              {
                time: "9:00 - 9:35",
                title: "Pivotal Response treatment: Research Update on Pivotal Response Treatment for Autism.",
                speaker: "Dr. Antonio Hardan (K)"
              },
              {
                time: "9:35 - 10:05",
                title: "Exploring molecular pathways associated with autism using cell-based models and genomics approaches.",
                speaker: "Dr. Lawrence Stanton (K)"
              },
              {
                time: "10:05 - 10:35",
                title: "The power of networks in autism research",
                speaker: "Dr. Mayada Elsabbagh (K)"
              },
              {
                time: "10:35 - 11:05",
                title: "Parent-Therapist Dynamic Collaboration",
                speaker: "Dr. Asmahan Saleh Khalil (K)"
              },
              {
                time: "11:05 - 11:15",
                title: "Coffee Break",
                speaker: ""
              },
              {
                time: "11:15 - 11:35",
                title: "A Systematic Review of Augmented Reality-Aided (ARA) Reading Interventions for Students with Autism Spectrum Disorder (ASD)",
                speaker: "Ms. Farah Al Zain (A)"
              },
              {
                time: "11:35 - 11:55",
                title: "Understanding Adolescence and Adults with Autism in Arab Countries",
                speaker: "Ms. Alain Yammine (A)"
              },
              {
                time: "11:55 - 12:15",
                title: "Autistic Adolescents in Saudi Arabia: Self-Determination and Environment",
                speaker: "Mr. Saleh Aba Alkhayl (A)"
              },
              {
                time: "12:15 - 12:30",
                title: "Discussion",
                speaker: "All"
              },
              {
                time: "12:30 - 13:15",
                title: "Lunch and prayer",
                speaker: ""
              },
            ]
          },
          {
            sessionNum: "Session (2)",
            moderator: "Moderator: Dr. Aiiat Alwahid (S)",
            events: [
              {
                time: "13:15 - 13:45",
                title: "Assessment in autism: Novel Instruments to Assess Core Features of Autism",
                speaker: "Dr. Antonio Hardan (K)"
              },
              {
                time: "13:45 - 14:15",
                title: "Latest research on sexual health education in the Arab world",
                speaker: "Dr. Asmahan Saleh Khalil (K)"
              },
              {
                time: "14:15 - 14:45",
                title: "The WHO Caregiver Skills Training Program",
                speaker: "Dr. Mayada Elsabbagh (K)"
              },
              {
                time: "14:45 - 15:05",
                title: "The reality of applying evidence-based behavioral practices with individuals with autism spectrum disorder.",
                speaker: "Ms.Afnan Majed Alsaeidi (A)"
              },
              {
                time: "15:05 - 15:25",
                title: "Services of Applied Behavior Analysis in Saudi Arabia: Challenges and Needs",
                speaker: "Mr. Abdullah Alotaibi (A)"
              },
              {
                time: "15:25 - 15:45",
                title: "The Emergence of Naming Repertoire in a Child Diagnosed with Autism as a Function of Multiple Exemplar instruction",
                speaker: "Ms. Esraa Douran (A)"
              },
              {
                time: "15:45 - 16:00",
                title: "Discussion",
                speaker: "All"
              },
            ]
          }
        ]
      },
      {
        day: "DAY 2",
        date: "Wednesday 24th April 2024",
        sessions: [
          {
            sessionNum: "Session (3)",
            moderator: "Moderator: Dr. Amel Alawami (S)",
            events: [
              {
                time: "8:30",
                title: "Registration",
                speaker: ""
              },
              {
                time: "9:00 - 9:35",
                title: "Care for people with ASD in resource-challenged settings",
                speaker: "Prof. Gaziudin (K)"
              },
              {
                time: "9:35 - 10:05",
                title: "Developing an Objective Autism Risk Index Using Arabic Remote Eye Tracking Paradigm for the Early Screening and Diagnosis of Autism Spectrum Disorder",
                speaker: "Dr. Fouad Alshaban (K)"
              },
              {
                time: "10:05 - 10:25",
                title: "Compassionate and Ethics in building an autism supportive institution",
                speaker: "Dr. Noor Syed (K)"
              },
              {
                time: "10:25 - 10:45",
                title: "Planning tools to support effective transition to adulthood for individuals with autism",
                speaker: "Dr. Nasiah Cirincione (K)"
              },
              {
                time: "10:45 - 11:00",
                title: "Coffee Break",
                speaker: ""
              },
              {
                time: "11:00 - 11:20",
                title: "Complementary Therapy in ASD: - Efficacy and Rationale",
                speaker: "Dr. Basma Al Jabri (S)"
              },
              {
                time: "11:20 - 11:40",
                title: "The Effects of Speaker Immersion on Independent Speaker Behavior in non-instructional Settings",
                speaker: "Ms. Samah Khashoggi (A)"
              },
              {
                time: "11:40 - 12:00",
                title: "Views and Responses of Students with Autism Spectrum Disorder and Their Families on Services and Support from Higher Education Institutions",
                speaker: "Ms. Asmaa AlZahrani (A)"
              },
              {
                time: "12:00 - 12:20",
                title: "A Review of Behavioral Interventions Used to Improve Menstrual Hygiene Independence in Young Women with Developmental Disabilities",
                speaker: "Ms. Sarah Alzahrani (A)"
              },
              {
                time: "12:20 - 12:30",
                title: "Discussion",
                speaker: "All"
              },
              {
                time: "12:30 - 13:15",
                title: "Lunch and prayer",
                speaker: ""
              },
            ]
          },
          {
            sessionNum: "Session (4)",
            moderator: "Moderator: Dr.Elham Alhifithy (S)",
            events: [
              {
                time: "13:15 - 13:45",
                title: "Autism in Adults: The coming epidemic!",
                speaker: "Prof. Gaziudin (K)"
              },
              {
                time: "13:45 - 14:10",
                title: "Update on Saudi national screening program",
                speaker: "Dr.Saleh Alsalehi (S)"
              },
              {
                time: "14:10 - 14:30",
                title: "Profound Autism Research: Current Trends and Emerging Insights",
                speaker: "Dr. Anja Jelaska (A)"
              },
              {
                time: "14:30 - 14:50",
                title: "Autism and sexually related issues: are they addressed in Arab culture?",
                speaker: "Dr. Shahad Al Kahlifa(A)"
              },
              {
                time: "14:50 - 15:10",
                title: "Healthcare utilisation in Saudi Arabia for children with Autism Spectrum Disorder and comorbidities.",
                speaker: "Dr.Ahmad M Almai (A)"
              },
              {
                time: "15:10 - 15:30",
                title: "Efficacy of Play-Based Interventions for Children with ASD",
                speaker: "Ms. Rozan El-Khateeb (A)"
              },
              {
                time: "15:30 - 15:50",
                title: "Increase Acceptance of Cutting Nails with Learners with Autism Spectrum Disorder using Behavioral Strategies",
                speaker: "Ms.Sana Ibrahim (A)"
              },
              {
                time: "15:50 - 16:00",
                title: "Discussion",
                speaker: "All"
              },
            ]
          }
        ]
      }
    ];
    const morningWorkshops = [
      {
        id: 1,
        time: '10:00 - 12:00',
        title: 'Using Implementation Science in Early Intervention',
        speaker: 'Dr. Mayada Elsabbagh (K)'
      },
      {
        id: 2,
        time: '10:00 - 12:00',
        title: 'Biological Treatment of Autism: Medications, ECT and Novel Interventions',
        speaker: 'Prof. Gaziudin (K)'
      },
      {
        id: 3,
        time: '10:00 - 12:00',
        title: 'Psychopharmacology: Clinical and Research Update on the Psychopharmacological Treatment of Autism',
        speaker: 'Dr. Antonio Hardan (K)'
      }
    ];
  
    const afternoonWorkshops = [
      {
        id: 4,
        time: '13:00 - 15:00',
        title: 'Safeguarding individuals with autism: Programming for safety and independence skills',
        speaker: 'Dr. Asmahan Saleh Khalil (K)'
      },
      {
        id: 5,
        time: '13:00 - 15:00',
        title: 'Navigating Autism: Diagnosis, Comorbidities, and Strategies for Success',
        speaker: 'Prof. Gaziudin (K)'
      },
      {
        id: 6,
        time: '13:00 - 15:00',
        title: 'ABA-based training on personal hygiene and protection against sexual abuse for Autistic Individuals: Promoting Healthy Development',
        speaker: 'Ms. Abeer Alshareef sayedahemd (A) & Dr. Amel Alawami (S)'
      }
    ];


    // Modal.js (Basic Implementation)
const Modal = ({ isOpen, children, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
        <button className="modal-close" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};


    const renderConferenceSessions = () => {
      return conferenceSchedule.map((day, dayIndex) => (
        <div key={dayIndex}>
          <h3>{day.day} - {day.date}</h3>
          {day.sessions.map((session, sessionIndex) => (
            <div key={sessionIndex} className="session">
              <input
                type="checkbox"
                id={`session-${dayIndex}-${sessionIndex}`}
                value={`${dayIndex}-${sessionIndex}`}
                checked={selectedConferenceSessions.includes(`${dayIndex}-${sessionIndex}`)}
                onChange={handleConferenceChange}
              />
              <label htmlFor={`session-${dayIndex}-${sessionIndex}`}>{session.sessionNum} - {session.moderator}</label>
            </div>
          ))}
        </div>
      ));
    };
    const handleConferenceChange = (e) => {
      const { value, checked } = e.target;
      if (checked && !selectedConferenceSessions.includes(value)) {
        setSelectedConferenceSessions([...selectedConferenceSessions, value]);
      } else {
        setSelectedConferenceSessions(selectedConferenceSessions.filter(session => session !== value));
      }
    };
        
    const register=async(e)=>{
      e.preventDefault();
      if(!fullName || !password || !phone || !email){
        setError('All Fields must entered');
        setTimeout(() => setError(''),3000);
      return;
    }

      console.log('register');
      await axios.post("https://sarcbackend-8f58e8101861.herokuapp.com/registerUser",
        {name:fullName,phone,email,parcNo:parc,password,SCFHSNo}).then(async res => {
          console.log(res);
          try{
            const auth = getAuth();
            signInWithEmailAndPassword(auth, email, password)
              .then(async(userCredential) => {
                // Signed in 
                console.log(userCredential,'userCredential');
                console.log(userCredential.user,'userCredential.user');
                const userFirebase = userCredential.user;
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode,'errorCode');
                console.log(errorMessage,'errorMessage');
              });
              navigate('/sarc3/registerApplication')
          }
        catch(e) {
            console.log(e);
            switch(e.code){
              case 'auth/wrong-password':
                setError('Wrong password.'); 
                setTimeout(() => {setError("")}, 3000);
                break;
              case 'auth/invalid-login-credentials':
                setError('Email or password is Wrong.'); 
                setTimeout(() => {setError("")}, 3000);
                break;
              default:
                setError(e.code); 
            }
        };
           navigate('/sarc3/registerApplication')
      }).catch(error => {
        console.log(error)
        switch(error.response.status) {
          case 455:
                setError('Email already exist')
                setTimeout(() => {setError("")}, 3000);
                break;
          case 456:
                setError('invalid email')
                setTimeout(() => {setError("")}, 3000);
                break;
          case 457:
                setError('The password must be at least 6 characters.')
                setTimeout(() => {setError("")}, 3000);
                break;
          default :
          setError(error.response.data);
          setTimeout(() => {setError("")}, 3000);
      }})
    }

    const renderWorkshops = () => {
      let workshopsToDisplay;
      if (activeWorkshopTab === 'morning') {
        workshopsToDisplay = morningWorkshops;
      } else {
        workshopsToDisplay = afternoonWorkshops;
      }
  
      return (
        <>
          <div className="workshop-subtabs">
            <button onClick={() => setActiveWorkshopTab('morning')} className={activeWorkshopTab === 'morning' ? 'active-subtab' : ''}>Morning</button>
            <button onClick={() => setActiveWorkshopTab('afternoon')} className={activeWorkshopTab === 'afternoon' ? 'active-subtab' : ''}>Afternoon</button>
          </div>
          {workshopsToDisplay.map(workshopItem => (
            <div key={workshopItem.id} className="workshop-item">
              <input
                type="checkbox"
                id={`workshop-${workshopItem.id}`}
                value={workshopItem.title}
                checked={workshop.includes(workshopItem.title)}
                onChange={handleChange}
              />
              <label htmlFor={`workshop-${workshopItem.id}`}>
                {`${workshopItem.title} - ${workshopItem.speaker} - ${workshopItem.time}`}
              </label>
            </div>
          ))}
        </>
      );
    };
  return (
    <div className="container">
    <Header smallNav={
      <>
       <p style={{marginBottom:'10px'}}>Already have account</p>
      <button  className='logout-btn' onClick={() =>navigate('/login')}>login</button>
      </>
    }>
      <div className='register'> 
      <p>Already have account</p>
      <button onClick={() =>navigate('/login')}>login</button>
      </div>
      </Header>
      <div className="auth-register">
        <div className="img-part">
          <img src="/assets/auth-sarc.webp" alt='register'/>
          <p>Welcome to the Saudi Autism Research and Conference and Applied Behavioral Analysis! This is the largest annual gathering of autism researchers in Saudi Arabia.</p>
        </div>
        <div className="form-part">
          <p className='head'>Authentication page</p>
          <p className="details">For registration, we need the following data</p>
          {error && <Error msg={error}/>}
          <form onSubmit={register}>
            <div className="div-input">
              <label htmlFor='fullName'>Full name*</label>
              <input id="fullName" value={fullName} onChange={e=>setFullName(e.target.value)} type="text"/>
            </div>
            <div className="div-input">
              <label htmlFor='email'>Email Address*</label>
              <input id="email" value={email} onChange={e=>setEmail(e.target.value)} type="text"/>
            </div>
            <div className="div-input">
              <label htmlFor='password'>Password*</label>
              <input id="password" type='password' value={password} onChange={e=>setPassword(e.target.value)}/>
            </div>
            <div className="div-input">
              <label htmlFor='phone'>Phone number*</label>
              <input id="phone" value={phone} onChange={e=>setPhone(e.target.value)} type="text"/>
            </div>
            <div className="div-input">
              <label htmlFor='parc'>Parc number</label>
              <input id="parc" value={parc} onChange={e=>setParc(e.target.value)} type="text"/>
            </div>
            <div className="div-input">
              <label htmlFor='SCFHSNo'>SCFHS number</label>
              <input id="SCFHSNo" value={SCFHSNo} onChange={e=>setSCFHSNo(e.target.value)} type="text"/>
            </div>
            {/* <div className="tabs">
        <button onClick={() => setActiveTab('workshops')} className={activeTab === 'workshops' ? 'active-tab' : ''}>Workshops</button>
        <button onClick={() => setActiveTab('mainConference')} className={activeTab === 'mainConference' ? 'active-tab' : ''}>Main Conference</button>
      </div> */}

      {/* {activeTab === 'workshops' && (
        <div className="workshops-content">
          {renderWorkshops()}
        </div>
      )}

      {activeTab === 'mainConference' && (
        <div className="conference-content">
          {renderConferenceSessions()}
        </div>
      )} */}
           <button
  className='next'
  style={{ marginBottom: '40px' }}
  onClick={() => setIsOpen(true)}
  // disabled={selectedConferenceSessions.length === 0}
>
  Submit
</button>

          </form>
        </div>
      </div>
  </div>
  )
}

export default Register