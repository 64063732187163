import React, { useState } from 'react';
import '../css/customModal.css';


function CustomModal3({ isOpen, onClose, appemail ,onDelete}) {
 


    const modalClassName = isOpen ? 'custom-modal show' : 'custom-modal';

    // const handleSendBill = () => {
    //     onSendBill(paymentUrl);
    //     onClose();
    // };

    return (
        <div className={modalClassName}>
            <div className="custom-modal-content">
                <span className="close-btn" onClick={onClose}>&times;</span>
                <div className="modal-body">
                    
                    <h4 htmlFor="paymentUrl">Are you sure you wnt to delete 
                    <b className='text-danger'> {appemail}</b> application? </h4>
                    
                </div>
                <div className="modal-footer">
                    <button className="btn btn-primary" onClick={onDelete} >Yes</button>
                    <button className="btn btn-secondary mt-3" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default CustomModal3;
