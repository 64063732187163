import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, ListGroup, Button, Badge, FormControl } from 'react-bootstrap';

import { useUserAuth } from '../context/userContext'
import { Header } from '../Components/Header';

function Campaign() {
  // State to store the list of all emails, the filtered list, and selected emails
  const [emails, setEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true); // State for collapse/expand
  const [isWritingMessage, setIsWritingMessage] = useState(false); // State to track message writing step
  const [message, setMessage] = useState(''); // State to store the message
  const [newEmail, setNewEmail] = useState(''); // State to store newly added email
  const { user } = useUserAuth();
  const [subject, setSubject] = useState(''); // State to store the subject of the message

  // Function to fetch emails from the API
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get('https://sarcbackend-8f58e8101861.herokuapp.com/allEmails'); // Update with your API endpoint
        setEmails(response.data);
        setFilteredEmails(response.data);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
  }, []);

  // Function to handle search input change and filter emails
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    // Filter emails based on search term
    const filtered = emails.filter((email) =>
      email.toLowerCase().includes(searchTerm)
    );
    setFilteredEmails(filtered);
  };

  // Function to handle checkbox change and update selected emails
  const handleCheckboxChange = (email) => {
    const selectedIndex = selectedEmails.indexOf(email);
    let updatedSelectedEmails = [...selectedEmails];

    if (selectedIndex === -1) {
      updatedSelectedEmails.push(email); // Add email if not already selected
    } else {
      updatedSelectedEmails.splice(selectedIndex, 1); // Remove email if already selected
    }

    setSelectedEmails(updatedSelectedEmails);
  };

  // Function to handle adding a new email manually
  const handleAddEmail = () => {
    if (newEmail.trim() !== '') {
      setEmails([...emails, newEmail.trim()]); // Add the new email to the emails array
      setNewEmail(''); // Clear the input field after adding the email
    }
  };

  // Function to handle sending the campaign to paid applications




  
  const sendCertificatesABA = () => {

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificatesABA', {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
      
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };
  
  const sendCertificatesEarlyIntervention = () => {

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificatesEarlyIntervention', {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
      
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };
  const sendCertificateIntroduction = () => {

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificateIntroduction', {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
      
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };
  const sendCertificatesPsychopharmacology = () => {

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificatesPsychopharmacology', {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
      
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };
  const sendCertificatesSafeguarding = () => {

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificatesSafeguarding', {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
      
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };
  const sendCertificatesECT = () => {

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificatesECT', {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
      
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };



  
  const sendCertificates = () => {
 

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificates', {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
        setSelectedEmails([]); // Reset selected emails
        setIsWritingMessage(false); // Reset message writing step
        setMessage(''); // Reset message
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };
const sendCampaignForPaid = () => {
    if (!message) {
      alert('Please write a message for the campaign.');
      return;
    }
    if (!subject) {
      alert('Please write a Subject for the campaign.');
      return;
    }

    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCampaignForPaidStatus', {
      selectedEmails: selectedEmails,
      message: message,
      subject: subject
    }, {
      headers: {
        "auth": user.accessToken,
      }
    })
      .then(response => {
        console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
        setSelectedEmails([]); // Reset selected emails
        setIsWritingMessage(false); // Reset message writing step
        setMessage(''); // Reset message
      })
      .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
      });
  };
  // Function to toggle collapse/expand state
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Function to handle "Next" button click
  const handleNextClick = () => {
    setIsWritingMessage(true);
  };
 // Function to handle sending the selected emails to the backend
 const sendCampaign = () => {
  
    axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/sendCertificates',{
        headers: {
            "auth": user.accessToken,
        }
    })
    .then(response => {
        // console.log('Campaign sent successfully:', response.data);
        alert('Campaign sent successfully.');
     
    })
    .catch(error => {
        console.error('Error sending campaign:', error);
        alert('Error sending campaign. Please try again later.');
    });
};
  return (
    <Container>
      <Header />
      <Row className="justify-content-center mt-5">
        <Col md={8}>
          <h1 className="text-center mb-4">Campaign</h1>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <Form.Control
              type="text"
              placeholder="Search by email"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            {isCollapsed ? (
              <Button className='w-50' variant="primary" onClick={toggleCollapse}>
                Open List
              </Button>
            ) : (
              <Button className='w-50' variant="secondary" onClick={toggleCollapse}>
                Close List
              </Button>
            )}
          </div>
          {!isCollapsed && (
            <ListGroup className="mt-3">
              {filteredEmails.map((email) => (
                <ListGroup.Item key={email}>
                  <Form.Check
                    type="checkbox"
                    id={email}
                    label={email}
                    checked={selectedEmails.includes(email)}
                    onChange={() => handleCheckboxChange(email)}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          <div className="text-center mt-4">
            {!isWritingMessage && (
              <Button onClick={handleNextClick} className="ml-3">Next Or Send For Paid Applictoins</Button>
            )}
          </div>
          {isWritingMessage && (
            <Form className="mt-4">
              <Form.Group controlId="subjectInput">
                <Form.Label>Subject:</Form.Label>
                <Form.Control
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="messageTextarea">
                <Form.Label>Write your message:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} // Update the message state
                />
              </Form.Group>
              <div className="text-center">
              <Button variant='secondary' className='mb-2 mt-2 ' onClick={() => setIsWritingMessage(false)}>Back</Button>
                <Button onClick={sendCampaign} className="ml-3">Send Campaign</Button>
            
                <Button variant='warning' onClick={sendCampaignForPaid} className="ml-3 mt-3">Send Campaign to Paid Applications</Button>
              </div>
            </Form>
          )}
        </Col>
        <Col md={4}>
          <h5>Selected Emails</h5>
          <div>
            {selectedEmails.map((email) => (
              <h6 key={email} variant="primary" className="mr-2 mb-2 h4"><Badge>{email}</Badge></h6>
            ))}
          </div>
          {/* Input field to add a new email */}
          <Form.Group controlId="formNewEmail">
            <Form.Label>Add New Email:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <Button onClick={handleAddEmail} className="mt-2">Add Email</Button>
          </Form.Group>
          <Form.Group >
           
            
            <Button onClick={sendCertificates} className="mt-5 bg-warning text-dark">Send Main Conference Certificate</Button>
            {/* <Button onClick={sendCertificatesABA} className="mt-5 bg-warning text-dark">Send ABA Workshop</Button>
            <Button onClick={sendCertificatesECT} className="mt-5 bg-warning text-dark">Send ECT Workshop</Button>
            <Button onClick={sendCertificatesSafeguarding} className="mt-5 bg-warning text-dark">Send Safeguarding Workshop</Button>
            <Button onClick={sendCertificatesPsychopharmacology} className="mt-5 bg-warning text-dark">Send Psychopharmacology Workshop</Button>
            <Button onClick={sendCertificatesEarlyIntervention} className="mt-5 bg-warning text-dark">Send EarlyIntervention Workshop</Button>
            <Button onClick={sendCertificateIntroduction} className="mt-5 bg-warning text-dark">Send Introduction Workshop</Button> */}

          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}

export default Campaign;
