import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Header } from '../Components/Header';

function Partner1() {
  return (
    <Container>
      <Header/>
   
      <Row className="justify-content-center mt-5" style={{backgroundColor:'#3281df' }}>
         <div className='col-6'>
           <img src="/assets/partnerlogo2.png" alt="partner logo" className='img-fluid'  style={{maxWidth:'120%',maxHeight:'100%'}}/>
         </div>
      </Row>
      <Row className='text-center h1'> <p className="mt-3 text-warning">من التمكين .. إلى التميز</p></Row>
      {/* Launch Header and Paragraph */}
      <Row className="rtl-header mt-5">
        <Col xs={12} className="text-right">
          <h2 className='text-end text-primary'>انطلاقة المركز</h2>
          <h4 className="mt-3 text-end " dir='rtl'>
            تحت رعاية وزارة الموارد البشرية والتنمية الاجتماعية والبنوك السعودية ممثلة في البنك المركزي السعودي، تم إنشاء مركز التميز للتوحد الذي يهدف إلى خدمة الأفراد ذوي اضطراب طيف التوحد وأسرهم وتحسين جودة حياتهم ودعم تطوير خدمات الرعاية 
            التأهيلية لهم في المملكة العربية السعودية .</h4>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col xs={12} className="text-center mt-5">
          <h2 className='text-warning'>المانحين</h2>
          <img src="/assets/givers.png" alt="givers" className="img-fluid mt-3" />
        </Col>
      </Row>
      <Row className="rtl-header mt-5">
        <Col xs={12} className="text-right">
          <h2 className='text-end text-primary'>الرؤية</h2>
          <h4 className="mt-3 text-end" dir='rtl'>
            أن نكون مرجعًا رائدًا وطنيًا للخدمات والرعاية المقدمة للمشخصين بأضطراب طيف التوحد وأسرهم.
          </h4>
        </Col>
      </Row>

      {/* Mission Section */}
      <Row className="rtl-header mt-5">
        <Col xs={12} className="text-right">
          <h2 className='text-end text-primary'>الرسالة</h2>
          <h4 className="mt-3 text-end " dir='rtl'>
            تمكين أفراد المشخصين باضطراب طيف التوحد من أن يعيشوا حياة منتجة ورغيدة، وذلك من خلال وضع وتطبيق معايير مرجعية للرعاية المتميزة في جميع أنحاء المملكة، بالإضافة إلى زيادة الاندماج في المجتمع عن طريق رفع القبول والوعي المجتمعي.
          </h4>
        </Col>
      </Row>

      {/* Values Section */}
      <Row className="rtl-header mt-5">
        <Col xs={12} className="text-center">
          <h2 className="text-center text-primary">القيم</h2>
          <img src="/assets/values.png" alt="values" className="img-fluid mt-3" />
        </Col>
      </Row>

      {/* Objectives Section */}
      <Row className="rtl-header mt-5">
  <Col xs={12} className="text-end">
    <h2 className='text-primary'>أهداف مركز التميز للتوحد </h2>
    <ul className="mt-3 rtl-text text-end">
      <li className='h5 ' dir='rtl'>التقييم الشامل في مجال تحليل السلوك التطبيقي وعلاج النطق واللغة والعلاج الوظيفي لتحديد الاحتياج ووضع خطة علاجية فردية شاملة .</li>
      <li className='h5 'dir='rtl'>العلاج المختص بالسلوك والتواصل الوظيفي.</li>
      <li className='h5 'dir='rtl'>برنامج التأهيل المكثف والبرامج التأهيلية المتخصصة.</li>
      <li className='h5 'dir='rtl'>الدعم الأسري والمجتمعي والتوعية بضعف التواصل الاجتماعي.</li>
      <li className='h5 'dir='rtl'>الدعم النفسي والاجتماعي والتوجيه المهني للأفراد ذوي اضطراب طيف التوحد.</li>
    </ul>
    {/* Add more sections as needed */}
  </Col>
</Row>


    
      <Row className="rtl-header mt-5">
        <Col xs={12} className="text-right">
          <h2 className="text-end text-primary" >الخدمات</h2>
          <ul dir='rtl' className="list-unstyled text-end mt-3">
    <li className='h5'  dir='rtl'>- مشاكل اإلطعام وانتقائية الطعام.</li>
    <li className='h5'>- التقييم النمائي الشامل.</li>
    <li className='h5'>- الخدمات المنزلية.</li>
    <li className='h5'>- البرنامج التأهيلي المكثف.</li>
    <li className='h5'>- التأهيل المهني.</li>
    <li className='h5'>- الباقة الواحدة- 24 جلسة في عالج التخاطب واللغة أو العالج الوظيفي.</li>
    <li className='h5'>- تدريب األسرة عن بعد.</li>
    <li className='h5'>- المشاكل السلوكية الشديدة والتواصل الوظيفي- 24 جلسة.</li>
    <li className='h5'>- برنامج التهيئة للمدرسة.</li>
    <li className='h5'>- استشارة مجانية.</li>
</ul>

        
        </Col>
      </Row>


      {/* <Row className="rtl-header mt-5">
        <Col xs={12} className="text-center">
          <h2>إدارة التنفيذية</h2>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3">
        <Col xs={6} className="text-center">
          <img src="/assets/manege.png" alt="management" className="img-fluid mt-3" />
        </Col>
      </Row> */}

     
      <Row className="rtl-header mt-5">
        <Col xs={12} className="text-center  d-flex justify-content-center">
        <h2 style={{ width:'70%',
    background: 'linear-gradient(to right, #4a9055, #2f80ed)',
    borderRadius: '0% 50% 0% 50%',
    padding: '20px',color:'white'
}}>
    لزيارة موقع المركز وللتسجيل في الخدمات
</h2>
<br/>

        </Col>
      </Row>
      <Row className="rtl-header mt-5">
        <Col xs={12} className="text-center  d-flex justify-content-center">
        <a className='h2' href="https://acesaudi.org/newintake/" target="_blank" style={{ color: 'black', textDecoration: 'underline' }}>
        اضغط هنا
      </a>
<br/>

        </Col>
      </Row>
     
      {/* <Row className="justify-content-center mt-3">
        <Col xs={12} className="text-center">
          <img src="/assets/effect.png" alt="impact makers" className="img-fluid mt-3" />
        </Col>
      </Row> */}
    </Container>
  );
}

export default Partner1;
