import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/userContext';
export const Soon = () => {
  const navigate = useNavigate()
  const [timerDays,setTimerDays] = useState('00')
  const [timerHours,setTimerHours] = useState('00')
  const [timerMinutes,setTimerMinutes] = useState('00')
  const [timerSeconds,setTimerSeconds] = useState('00');
  const { userDetails,user} = useUserAuth();
  let interval = useRef();
  const startTimer =() =>{
    const countdownDate = new Date('Apr 23 2024 00:00:00').getTime();
    interval = setInterval(() =>{
      const now = new Date().getTime();
      const distane = countdownDate - now;
      const days = Math.floor(distane / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distane % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)))
      const mins = Math.floor((distane % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distane % (1000 * 60)) / 1000);

      if(distane < 0){
        //stop timer
        clearInterval(interval.current);
      } else{
        setTimerDays(days)
        setTimerHours(hours)
        setTimerMinutes(mins)
        setTimerSeconds(seconds)
      }
    },1000)
  }
  useEffect(() =>{
    startTimer();
    return ()=> { clearInterval(interval.current);}
  })
  return (
    <div className="soon">
      <div className="div">
        <div className="back-sqr">
          <div className="front-sqr">
            <div className='image'><img src="/assets/clock.webp" alt='clock'/></div>
            <p>Days left before the opening</p>
          </div>
          <div className="timer">
            <div className="sec">
              <p className="title">Days</p>
              <p className="value">{timerDays}</p>
            </div>
            <p className="divider">:</p>
            <div className="sec">
              <p className="title">Hours</p>
              <p className="value">{timerHours}</p>
            </div>
            <p className="divider">:</p>
            <div className="sec">
              <p className="title">Minutes</p>
              <p className="value">{timerMinutes}</p>
            </div>
            <p className="divider">:</p>
            <div className="sec">
              <p className="title">Seconds</p>
              <p className="value">{timerSeconds}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="div">
        <div className="coming-img">
          <img style={{cursor:'pointer'}} onClick={() => navigate(user?'/sarc3/registerApplication':'/register')} src={user?'/assets/lern.png':'/assets/nonregisterd.png'}  alt='now'/>
        </div>
      </div>
   </div>
  )
}
