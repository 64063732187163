import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import img from '../assets/Introduction.png';
import { useUserAuth } from '../context/userContext';
import axios from 'axios';

function Introduction() {
  const { id } = useParams();


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`https://sarcbackend-8f58e8101861.herokuapp.com/getUserDetails/${id}`);
        const user = response.data;

        const generateCertificate = () => {
          const doc = new jsPDF();

          // Add background image
          doc.addImage(
            img,
            'PNG',
            0,
            0,
            doc.internal.pageSize.getWidth(),
            doc.internal.pageSize.getHeight()
          );

          // Add recipient name
          doc.setFontSize(30);
          doc.setFont('helvetica');
          doc.text(user.name, 35, 130, { align: 'left' });

          // Add course name (Assuming course name is provided or fetched based on ID)
          doc.setFontSize(20);
        //   doc.text('Main Conference', 105, 195, { align: 'center' });

          // Save the PDF and trigger download
          doc.save(`${user.name}-certificate.pdf`);
        };

        generateCertificate();
      } catch (error) {
        console.log('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [id]);

  return (
    <div>
      <p>Your certificate is being generated. If the download doesn't start, please click <a href="/">here</a>.</p>
    </div>
  );
}

export default Introduction;
