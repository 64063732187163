import React, {useState } from 'react'

export const Contact = () => {
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [lastName,setLastName] = useState("");
    const [firstName,setfirstName] = useState("");
    const [Message,setMessage] = useState("");

  return (
    <div className="contact" id='contact'>
          <div className="p-5 m-5">
            <h2>Contact Information</h2>
            <p className="contact-desc">Welcome to 3rd Saudi Autism Research & Applied Behavioral Analysis<br/> Conference!</p>
            <ul>
              <li><img alt='phone' src="/assets/phone-icon.svg"/>
              <p>920035900</p></li>
              <li><img alt='mail' src="/assets/mail-icon.svg"/>
              <p>SARC@KAAUH.edu.sa</p></li>
              <li><img alt='icon' src="/assets/location-icon.svg"/>
              <p>king abdullah bin abdulaziz university hospital</p></li>
            </ul>
            <div className="sqr"></div>
            <div className="circle"></div>
          </div>
          <div className="form-part">
            <form>
                <div className="form">
              <div className="input-section">
              <label htmlFor="firstName" className={`${firstName ? 'active-label': ''} `}>First Name</label>
              <input type="text" id="firstName" className={`${firstName ? 'active': ''} `} value={firstName} onChange={(e)=>setfirstName(e.target.value)}/>
              </div>
              <div className="input-section">
              <label htmlFor="lastName" className={`${lastName ? 'active-label': ''} `}>Last Name</label>
              <input type="text"  className={`${lastName  ? 'active': ''} `} id="lastName" value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
              </div>
            </div>
            <div className="form">
              <div className="input-section">
              <label htmlFor="email" className={`${email ? 'active-label': ''} `}>Email</label>
              <input type="text" id="email" className={`${email ? 'active': ''} `} value={email} onChange={(e)=> setEmail(e.target.value)}/>
              </div>
              <div className="input-section">
              <label htmlFor="phone" className={`${phone ? 'active-label': ''} `}>Phone Number</label>
              <input type="text" className={`${phone ? 'active': ''} `} id="phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
              </div>
            </div>
            <p className='label-select'>Select Subject?</p>    
            <div className='select-sec'>
                <div className='selection'>
                <input type="radio" id="1" name="General-Inquiry" value="1"/>
                <label for="1">General Inquiry</label>
                </div>
                {/* <div className='selection'>
                <input type="radio" id="2" name="General-Inquiry" value="2"/>
                <label for="2">General Inquiry</label>
                </div>
                <div className='selection'>
                <input type="radio" id="3" name="General-Inquiry" value="3"/>
                <label for="3">General Inquiry</label>
                </div>
                <div className='selection'>
                <input type="radio" id="4" name="General-Inquiry" value="4"/>
                <label for="4">General Inquiry</label>
                </div> */}
            </div>
            <div className="text-area">
              <label htmlFor="Message" className={`${Message ? 'active-label': ''} `}>Message</label>
              <input type="text" id="Message" placeholder='Write your message..' className={`${Message ? 'active': ''} `} value={Message} onChange={(e)=> setMessage(e.target.value)}/>
            </div>
            <div className='btn-section'>
            <button className={`${Message ? 'opacity' :''}`}>Send Message</button>
            </div>
            </form>
          </div>
     </div>
  )
}
