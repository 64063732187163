import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Analysis() {
    const [allApplications, setAllApplications] = useState([]);
    const [todayAndYesterdayApplications, setTodayAndYesterdayApplications] = useState([]);
    const [applicationsWithWorkshop, setApplicationsWithWorkshop] = useState([]);

    const fetchApplicationsWithWorkshop = async () => {
        try {
            const res = await axios.get('https://sarcbackend-8f58e8101861.herokuapp.com/applicationsWithWorkshop');
            setApplicationsWithWorkshop(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchApplicationsWithWorkshop();
    }, [])
    const fetchAllApplications = async () => {
        try {
            const res = await axios.get('https://sarcbackend-8f58e8101861.herokuapp.com/allApplications');
            setAllApplications(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchTodayAndYesterdayApplications = async () => {
        try {
            const res = await axios.get('https://sarcbackend-8f58e8101861.herokuapp.com/todayAndYesterdayApplications');
            setTodayAndYesterdayApplications(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchAllApplications();
        fetchTodayAndYesterdayApplications();
    }, []);

    return (
        <div>
            <h1>All Applications</h1>
            <table>
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Date</th>
                        {/* Add other table headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {allApplications.map(application => (
                        <tr key={application._id}>
                            <td>{application.userId}</td>
                            <td>{application.date}</td>
                            {/* Add other table data cells as needed */}
                        </tr>
                    ))}
                </tbody>
            </table>

            <h1>Today and Yesterday Applications</h1>
            <table>
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Date</th>
                        {/* Add other table headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {todayAndYesterdayApplications.map(application => (
                        <tr key={application._id}>
                            <td>{application.userId}</td>
                            <td>{application.date}</td>
                            {/* Add other table data cells as needed */}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>
           
           
            <p>Total Applications with Workshop: {applicationsWithWorkshop.length}</p>
        </div>
        </div>
    );
}

export default Analysis;
