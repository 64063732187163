import React from 'react'

export const Objectives = () => {
  return (
    <div className="objectives">
      <h2 className="objs-title">Objectives</h2>
      <div className="row">
        <div className="col-md-3 col-sm-6 marg">
          <div className="obj-content">
            <p className="obj-title">discussions</p>
            <p className="obj-desc">- Discuss the most updated autism research projects in the region</p>
            <p className="obj-desc">- Review recommendations for guideline establishment and evidence based</p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 marg">
          <div className="obj-content">
            <p className="obj-title">practices</p>
            <p className="obj-desc">- Share recent research in the area of Autism within a multidisciplinary</p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 marg">
          <div className="obj-content">
            <p className="obj-title">approach</p>
            <p className="obj-desc">- Organize a platform for collaboration among researchers from different</p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 marg">
          <div className="obj-content">
            <p className="obj-title">disciplines</p>
            <p className="obj-desc">- Constant a venue for the national autism research community to exchangeinformation and to identify potential areas for collaboration</p>
            <p className="obj-desc">- Recognize SARC as the main host for autism researchers in the region.</p>
          </div>
        </div>
      </div>
     </div>
  )
}
