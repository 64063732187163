// import { getAuth,signOut } from 'firebase/auth';
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUserAuth } from "../context/userContext";
import { getAuth, signOut } from 'firebase/auth';

export const Header = ({ children, img, smallNav }) => {
  const { userDetails, isAdmin,user,setIsAdmin } = useUserAuth();
  const location = useLocation();
  // const auth = getAuth();
  //   async function handleLogout() {
  //     signOut(auth);
  //     setIsAdmin(false)
  //     window.location.href='/login'
  //     // navigate("/login");
  // }
  const isMainsPath = location.pathname === "/MainConference";

  const isWorkshopsPath = location.pathname === "/workshops";
  const isSARC3Path =
    location.pathname === "/sarc3" || isWorkshopsPath || isMainsPath;

  const showMenu = () => {
    document.getElementById("mobile-menu").style.right = "0px";
    console.log("show");
  };
  const hideMenu = () => {
    document.getElementById("mobile-menu").style.right = "-250%";
    console.log("hide");
  };


  const auth = getAuth();

  const handleLogout = async () => {
      isAdmin && setIsAdmin(false);
      signOut(auth);
      window.location.href = '/login';
  };

  return (
    <header className="home-header">
      <div>
        <a href="/">
          <img alt="home" src={`${img ? img : "..//assets/sarc3.svg"}`} />
        </a>
      </div>
      <ul className="big-nav">
        <li>
          <a href={isSARC3Path||isMainsPath||isWorkshopsPath?'/sarc3':' /'} className={location.pathname === "/" ? "active" : ""}>
           {isSARC3Path||isMainsPath||isWorkshopsPath?'Sarc3':' Home'}
          </a>
        </li>
        {/* <li><Link to='/topics' className={location.pathname === '/topics' ? 'active' : ''}>Topics</Link></li> */}
        {/* <li><Link to='/speakers' className={location.pathname === '/speakers' ? 'active' : ''}>Speakers</Link></li> */}
        {isSARC3Path && (
          <li>
            <Link to="/workshops" className={isWorkshopsPath ? "active" : ""}>
              Workshops
            </Link>
          </li>
        )}
        {isSARC3Path && (
          <li>
            <Link to="/MainConference" className={isMainsPath ? "active" : ""}>
              Main conference
            </Link>
          </li>
        )}
        {!isSARC3Path&& <li>
          <a
            href="/sarc3"
            className={location.pathname === "/sarc3" ? "active" : ""}
          >
            Sarc3
          </a>
        </li>}
        <li>
<a
  href="/partner"
  className={location.pathname === "/partner" ? "active" : ""}
>
Partner
</a>
</li>
        <li>
          <a
            href="/home#contact"
            className={location.pathname === "/contact" ? "active" : ""}
          >
            Contact
          </a>
        </li>
       {user&& <li><div class="">
                        <label for="profile2" class="profile-dropdown">
                            <input type="checkbox" id="profile2" />
                            <img src="https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png" />
                            <span>{user?.email || user?.name}</span>
                            <label for="profile2" class="dropdown-arrow"><i class="mdi mdi-menu"></i></label>
                            <div>{user?.id}</div>
                            <ul>
                                <li><a href={`/profile/${user.uid}`} ><i class="mdi mdi-account"></i>Profile</a></li>
                                {isAdmin&&<li><a href="/admin/manage"><i class="mdi mdi-settings"></i>Applications</a></li>
                                }
                                {isAdmin&&<li><a href="/admin/campaign">
                                  <i class="mdi mdi-settings"></i>Email Campaign </a></li>
                              }
                                {isAdmin && (
          <li>
            <a href="/sarc3/dashboard">Dashboard</a>
          </li>
        )}
                {isAdmin && (
          <li>
            <a href="/admin/users">Users</a>
          </li>
        )}
                                <li onClick={() => handleLogout()}><a href="#"><i class="mdi mdi-logout"></i>Logout</a></li>
                            </ul>
                        </label>
                    </div></li>}
        {/* {userDetails && <li><Link to={`/profile/${userDetails._id}`}>Profile</Link></li>} */}
      


      {!user &&isSARC3Path&& <li><Link to='/login'>Login</Link></li>} 
      </ul>

      <div className="menu" onClick={showMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="mobile-menu" className="mobile-menu">
        <i onClick={hideMenu} className="fa-solid fa-xmark"></i>
        <div className="width-menu">
          <div className="logo-img">
            <img src="/assets/auth-sarc.webp" alt="header" />
          </div>
          <ul className="mob-nav">
          <li>
          <a href={isSARC3Path||isMainsPath||isWorkshopsPath?'/sarc3':' /'} className={location.pathname === "/"||location.pathname === "/sarc3" ? "active" : ""}>
           {isSARC3Path||isMainsPath||isWorkshopsPath?'Sarc3':' Home'}
          </a>
        </li>
            {/* <li><Link to='/topics' className={location.pathname === '/topics' ? 'active' : ''}>Topics</Link></li> */}
            {/* <li><Link to='/speakers' className={location.pathname === '/speakers' ? 'active' : ''}>Speakers</Link></li> */}
            {isSARC3Path && (
          <li>
            <Link to="/workshops" className={isWorkshopsPath ? "active" : ""}>
              Workshops
            </Link>
          </li>
        )}
        {isSARC3Path && (
          <li>
            <Link to="/MainConference" className={isMainsPath ? "active" : ""}>
              Main conference
            </Link>
          </li>
        )}
        {!isSARC3Path&& <li>
          <a
            href="sarc3"
            className={location.pathname === "/sarc3" ? "active" : ""}
          >
            Sarc3
          </a>
        </li>}
        <li>
<a
  href="/partner"
  className={location.pathname === "/partner" ? "active" : ""}
>
Partner 
</a>
</li>
        <li>
          <a
            href="#contact"
            className={location.pathname === "/contact" ? "active" : ""}
          >
            Contact
          </a>
        </li>

            {userDetails && (
              <li>
                <Link to={`/profile/${userDetails._id}`}>Profile</Link>
              </li>
            )}
            {isAdmin && (
              <li>
                <Link to="/sarc3/dashboard">Dashboard</Link>
              </li>
            )}
          </ul>
          {smallNav}
        </div>
      </div>
      {children}
    </header>
  );
};
