import React ,{useState}from 'react'
import '../css/maps.css'
export const Scientific = () => {
  const [Longitude, setLongitude] = useState()
  const [Latitude, setLatitude] = useState()

  return (
    <>
    <div className="scientific">
          <div className="img-part">
            <img src="/assets/committee.webp" alt='committee'/>
          </div>
          <div className="text-part">
            <p className="title">Scientific Committee Members</p>
            <p>Dr. Saleh AISalhi</p>
            <p>Dr.Elham Alhifithy</p>
            <p>Dr. Shahad Alshareef</p>
            <p>Dr.Amal Alawami</p>
            <p>Dr. Hayat Muschab</p>
            <p>Dr. Mohammed Alqahtani</p>
            <p>Dr. Lama Taher</p>
            <p>Dr. Rehab Alrajihi</p>
            <p>Dr.Basma Aljabre</p>
            <p>Dr. Ayat Alwhid</p>
          </div>
     </div>
     <div className="text-part">
            <h2>Location</h2>
            <p>king abdullah bin abdulaziz university hospital</p>
           
                     </div>
     <div className="responsive-map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.6914765956312!2d46.743567000000006!3d24.8402224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efc0400c92f1d%3A0x30911b7b909b4db!2sAl%20Faredah!5e0!3m2!1sen!2sjo!4v1709233788798!5m2!1sen!2sjo"   style={{border:'5px solid #C1D34C'}}  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

    </>
  )
}
