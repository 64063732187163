import React,{ createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail
} from "firebase/auth";
import { auth } from "./firebase.config";
import axios from "axios";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading ,setLoading] = useState(true);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  async function forgotPassword(email){
    const config = {
      url: "https://www.sarc-kaauh.com/login",
      handleCodeInApp: true
  }

    await auth.sendPasswordResetEmail(email,config)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);
      // setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
      onAuthStateChanged(auth,async authUser => {
        if (authUser) {
          try {
            const res = await axios.get("https://sarcbackend-8f58e8101861.herokuapp.com/user",{
            headers:{
            "auth": authUser.accessToken
            }})
            console.log(res.data,'res');
            setUserDetails(res.data)
            if(res.data && res.data.role && res.data.role === 'admin'){
              setIsAdmin(true);
              console.log('admintrue');
              setLoading(false);
            }
            else{
              setIsAdmin(false)
              console.log('adminfalse');
              setLoading(false);
            }
      // console.log(userDetails,'userDetails');
      // console.log(isAdmin,'isAdmin');
        } catch (err) {
            console.log(err,'errorfrom');
            if(err.response.status) console.log(err.response.status,'err.response.status');
            
            setUser(null);
            setUserDetails({});
            setLoading(false);
        }
        } else {
          setUser(null);
          console.log("logout");
          setLoading(false);
        }
      })
      
  },[])

  return (
    <userAuthContext.Provider
      value={{ user,userDetails, setUserDetails,logIn, signUp, logOut,isAdmin, setIsAdmin, forgotPassword}}
    >
      {!loading && children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}