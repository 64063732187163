import React, { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/userContext";
import axios from "axios";
import { Error } from "../Components/Error";
import Modal from "../Components/Modal";
import "../css/attends.css";
import { getAuth, signOut } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Attendee = () => {
  const [totalPrice, setTotalPrice] = useState(0); // Define totalPrice state

  const { userDetails, user, isAdmin, setIsAdmin } = useUserAuth();
  const navigate = useNavigate();
  const [workshop, setWorkshop] = useState([]);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [userOldWorkshops, setUserOldWorkshops] = useState([]);
  const morningWorkshops = [
    {
      id: 1,
      time: "10:00 - 12:00",
      title: "Using Implementation Science in Early Intervention",
      speaker: "Dr. Mayada Elsabbagh (K)",
      price: 500, 
      status: 'unpaid'
    },
    {
      id: 2,
      time: "10:00 - 12:00",
      title:
        "Biological Treatment of Autism: Medications, ECT and Novel Interventions",
      speaker: "Prof. Gaziudin (K)",
      price: 500, 
      status: 'unpaid'
    },
    {
      id: 3,
      time: "10:00 - 12:00",
      title:
        "Psychopharmacology: Clinical and Research Update on the Psychopharmacological Treatment of Autism",
      speaker: "Dr. Antonio Hardan (K)",
      price: 500, 
      status: 'unpaid'
    },
  ];

  const afternoonWorkshops = [
    {
      id: 4,
      time: "13:00 - 15:00",
      title:
        "Safeguarding individuals with autism: Programming for safety and independence skills",
      speaker: "Dr. Asmahan Saleh Khalil (K)",
      price: 500,
      status: 'unpaid'
    },
    {
      id: 5,
      time: "13:00 - 15:00",
      title:
        "Introduction about Practical Functional Assessment & Skill Based Training",
      speaker: `Ms.Asma Alsaleh (A) 
      & Ms.Noha Almarzooq (A)`,
      price: 500, 
      status: 'unpaid'
    },
    {
      id: 6,
      time: "13:00 - 15:00",
      title:
        "ABA-based training on personal hygiene and protection against sexual abuse for Autistic Individuals: Promoting Healthy Development",
      speaker: "Ms. Abeer Alshareef sayedahemd (A) & Dr. Amel Alawami (S)",
      price: 500, 
      status: 'unpaid'
    },
  ];

  useEffect(() => {
    fetchUserApplications();
  }, []);
  const [itemsNo, setItemsNo] = useState(3);
  const [count, setCount] = useState(0);
  const fetchUserApplications = async () => {
    try {
      const res = await axios.get(
        "https://sarcbackend-8f58e8101861.herokuapp.com/userApplications",
        {
          headers: {
            "auth": user.accessToken,
            "memberId": user.uid,
            "itemsNo": itemsNo,
            "skip": count * 3
        }
        }
      );
      setUserOldWorkshops(res.data[0]?.workshop || []);
      console.log("old",res.data[0]?.workshop || [])
    } catch (error) {
      console.log(error);
      // Handle error
    }
  };
  const handleChange = (e, workshopItem) => {
    const { checked } = e.target;
  
    // Check if the workshop is in the morning session or afternoon session
    const isMorningWorkshop = morningWorkshops.some((item) => item.id === workshopItem.id);
    const isAfternoonWorkshop = afternoonWorkshops.some((item) => item.id === workshopItem.id);
  
    // Check if there's already a workshop selected from the same session
    const isMorningSelected = workshop.some((item) => morningWorkshops.some((workshop) => workshop.id === item.id));
    const isAfternoonSelected = workshop.some((item) => afternoonWorkshops.some((workshop) => workshop.id === item.id));
  
    // Check if the workshop has already been attended by the user
    const isWorkshopAttended = userOldWorkshops.some((item) => item.id === workshopItem.id);
  
    if (checked) {
      // If the workshop is already selected from the same session or attended, do not allow selection
      if ((isMorningWorkshop && isMorningSelected) || 
          (isAfternoonWorkshop && isAfternoonSelected) || 
          isWorkshopAttended) {
        // Display a message or handle the case as needed
        return;
      }
  
      // If the workshop is not in the user's old workshops, add it to the array
      setWorkshop((prevWorkshops) => [...prevWorkshops, workshopItem]);
    } else {
      // If the checkbox is unchecked, remove the workshop object from the array
      setWorkshop((prevWorkshops) =>
        prevWorkshops.filter((item) => item.id !== workshopItem.id)
      );
    }
  };
  

  const renderWorkshops = () => {
    const renderWorkshopCards = (workshops) => {
      return workshops.map((workshopItem) => {
        const isWorkshopSelected = workshop.some((item) => item.id === workshopItem.id);
        const isWorkshopOld = userOldWorkshops.some((item) => item.id === workshopItem.id);
  
        return (
          <div className="col-md-5" key={workshopItem.id}>
            <div className={`workshop-card ${isWorkshopOld ? 'disabled' : ''}`}>
              {!workshopItem.alwaysSelected && (
                <input
                  type="checkbox"
                  id={workshopItem.title}
                  checked={isWorkshopSelected}
                  disabled={isWorkshopOld}
                  onChange={(e) => handleChange(e, workshopItem)}
                />
              )}
              <label htmlFor={workshopItem.title}>
                <span className="workshop-info">
                  <span className="workshop-title">{workshopItem.title}</span>
                  <span className="workshop-time">{workshopItem.time}</span>
                  <br />
                  <span className="workshop-speaker">{workshopItem.speaker}</span>
                  <br />
                  <span className="workshop-price">{workshopItem.price} SAR</span> {/* Render workshop price */}
                </span>
              </label>
              {isWorkshopOld && <span className="workshop-note">This option was selected before</span>}
              {isWorkshopOld && <span className="workshop-note">You cannot select this option again</span>}
              {isWorkshopSelected && !workshopItem.alwaysSelected && <span className="workshop-note">You have already selected this option</span>}
            </div>
          </div>
        );
      });
    };
  
    // Add the Main Conference option
    const mainConferenceOption = {
      id: 7,
      title: "Main Conference - Selected by Default",
      time: "10:00 - 17:00",
      speaker: "Various Speakers",
      price: 450,
      alwaysSelected: true, // This option is always selected and cannot be deselected
    };
  
    const morningWorkshopCards = renderWorkshopCards(morningWorkshops);
    const afternoonWorkshopCards = renderWorkshopCards(afternoonWorkshops);
  
    return (
      <div>
        <div className="row">
          <div className="col-md-5">
            <div className="workshop-card disabled">
              <label htmlFor={mainConferenceOption.title}>
                <span className="workshop-info">
                  <span className="workshop-title">{mainConferenceOption.title}</span>
                  <span className="workshop-time">{mainConferenceOption.time}</span>
                  <br />
                  <span className="workshop-speaker">{mainConferenceOption.speaker}</span>
                  <br />
                  <span className="workshop-price">{mainConferenceOption.price} SAR</span> {/* Render workshop price */}
                </span>
              </label>
              <span className="workshop-note">This option is selected by default when registering as an attendee</span>
            </div>
          </div>
        </div>
        <h2>Morning Workshops <p style={{ fontSize: '15px', color: 'green' }}>1 allowed</p></h2>
        <div className="row">{morningWorkshopCards}</div>
        <h2>Afternoon Workshops <p style={{ fontSize: '15px', color: 'green' }}>1 allowed</p></h2>
        <div className="row">{afternoonWorkshopCards}</div>
      </div>
    );
  };
  

  const submitForm = () => {
    setIsOpen(false);
  if (!userDetails.name || !userDetails.email || !userDetails.phone) {
    setError("Fields that contain * must be entered");
    window.scroll(0, 0);
    setTimeout(() => setError(""), 4000);
    return;
  }

  // Calculate total price

  setIsOpen(true);
 
    axios
      .post(
        "https://sarcbackend-8f58e8101861.herokuapp.com/apply",
        {
          name: userDetails.name,
          email: userDetails.email,
          phone: userDetails.phone || userDetails.parcNo || "", // Use parcNo if available
          workshop,
          participateAs: "attendee",
        },
        {
          headers: {
            auth: user.accessToken,
          },
        }
      )
      .then(() => {
        toast.success("Regisrt as an Atendee Completed Successfully")

       navigate("/sarc3");
      })
      .catch((error) => {
        console.log(error);
        // Handle error
      });
  };
const handleOpenModal=()=>{
  setIsOpen(true)
  const workshopPrices = workshop.map(item => item.price);
  const totalWorkshopPrice = workshopPrices.reduce((total, price) => total + price, 0);
  const total = totalWorkshopPrice + 450; // 450 is the conference price
console.log("totl price",total)
  // Display total price in the modal
  setTotalPrice(total);
}
  const auth = getAuth();
  async function handleLogout() {
    isAdmin && setIsAdmin(false);
    signOut(auth);
    window.location.href = "/login";
    // navigate("/login");
  };

  return (
    <div style={{ position: "relative" }}>
     <Modal open={isOpen} onClose={() => setIsOpen(false)}>
  <i
    style={{ display: "block", color: "#636C87", fontSize: "64px" }}
    className="fa-solid fa-circle-exclamation"
  ></i>
  <h2
    style={{
      color: "#010B38",
      fontSize: "24px",
      fontWeight: 700,
      marginTop: "16px",
      marginBottom: "8px",
    }}
  >
    Do you want to save changes?
  </h2>
  <p
    style={{
      color: "rgba(1,11,56,.6)",
      fontSize: "14px",
      fontWeight: 500,
      marginBottom: "24px",
    }}
  >
    Total Price: {totalPrice} SAR
    <br />
    Last changes will be lost if you don't save them
  </p>
  <button className="save-btn" onClick={() => submitForm()}>
    Save
  </button>
  <button className="dont-btn" onClick={() => setIsOpen(false)}>
    Don't Save
  </button>
  <button
    className="cancel-btn text-danger "
    onClick={() => setIsOpen(false)}
  >
    Cancel
  </button>
</Modal>

      <div
        style={{ background: "#F5FEFD", width: "100%", paddingBottom: "1px" }}
      >
        <div className="container">
          <Header
            smallNav={
              <div className="user-icon-sm">
                <p>{userDetails && userDetails?.name?.slice(0, 2)}</p>
              </div>
            }
          ></Header>
        </div>
      </div>
      <div className="container">
        <div className="speaker-back">
          <h6
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sarc3/registerApplication")}
          >
            <span className="h6">{"<"}</span> Back to step 1
          </h6>
        </div>
      </div>
      <div className="speaker-form">
        <p className="join-head">Step 2</p>
        {/* <p className="speaker-body">
          Participate in the conference, gain knowledge, or network with others.
        </p> */}
        <form>
          <Error msg={error} />
          <div className="fields">
            <label htmlFor="title" className="h6 text-danger">
              Select from Workshops
            </label>
            <label htmlFor="title" className="h4 text-success">
              (Not required)
            </label>
            {renderWorkshops()}
          </div>
        </form>
        <button
          className="next"
          style={{ marginBottom: "40px" }}
          onClick={handleOpenModal}
     
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Attendee;
