import React from 'react'

export const Banner = () => {
  return (
    <div className="banner">
    <p className="p1">3RD SAUDI AUTISM RESEARCH & </p>
    <p className="p2">APPLIED BEHAVIOR ANALYSIS CONFERENCE</p>
   <div className="row">
    <div className="col-md-9 col-sm-12">

    <p className="p3-date">23-25/Apr/2024</p>
    <p className="p4-desc">Dear Researchers,</p>
    <p className="p4-desc">
     I am writing to welcome you to the Saudi Autism Research and Conference and Applied Behavioral Analysis. This is the biggest conference for ASD researchers in the region, and we are excited to have you here. The conference will be held on 23-25/April/2023 on Riyadh. We will have a variety of speakers, including leading experts of multi specialized team of psychologists, behavioral development physician, Education specialists of learning disabilities, psychologists, and Occupational Therapy in the field of ASD research. We will also have workshops, poster sessions, and networking opportunities. We hope that you will take advantage of all that the conference has to offer. We believe that this is a great opportunity for you to learn about the latest advances in ASD research, network with other researchers, and discuss your own work. We are also committed to making the conference accessible to everyone. We will have interpreters available for all sessions, and we will have a variety of accommodations available for people with disabilities. We hope to see you there! Sincerely,</p>
     <p className="last-p">Dr.Saleh Alsalehi - <span>Chairman of scientific program</span></p>
    </div>
    
    <div className="col-md-3 col-sm-12">
      <div className="img">
      <img src="/assets/buzzle.webp" alt='buzzle'/>
      </div>
    </div>
    </div>
   </div>
  )
}
