import React, { useState } from 'react';
import { Header } from '../Components/Header';
import { useUserAuth } from "../context/userContext";
import { Error } from '../Components/Error';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const { logIn } = useUserAuth();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !password) {
      setError('Email and password are required.');
      setTimeout(() => { setError("") }, 3000);
      return;
    }
    try {
      await logIn(email, password);
      navigate("/sarc3/registerApplication");
    } catch (e) {
      console.log(e);
      switch (e.code) {
        case 'auth/wrong-password':
          setError('Wrong password.');
          setTimeout(() => { setError("") }, 3000);
          break;
        case 'auth/invalid-login-credentials':
          setError('Email or password is wrong.');
          setTimeout(() => { setError("") }, 3000);
          break;
        default:
          setError(e.code);
      }
    };
  }

  return (
    <div className="container">
      <Header smallNav={<>
        <p style={{ marginBottom: '10px' }}>Create Account?</p>
        <button className='logout-btn' onClick={() => navigate('/register')}>Register</button></>
      }>
        <div className='register'>
          <p>Create Account?</p>
          <button onClick={() => navigate('/register')}>Register</button>
        </div>
      </Header>
      <div className='login'>
    <div className="row mb-3">
        <div className="col-md-6 col-sm-6">
            <img src='/assets/p1n.png' alt='partnership' className="img-fluid" style={{ maxWidth: '100%' }} />
        </div>
        <div className="col-md-6 col-sm-6">
            <img src='/assets/p2n.png' alt='partnership' className="img-fluid" style={{ maxWidth: '100%' }} />
        </div>
    </div>
    <div className="text-center mb-4">
        <img src='/assets/auth-sarc.webp' alt='login' style={{ maxWidth: '100%', height: 'auto' }} />
        <p className='login-head mt-3'>Login</p>
        <p className='login-body'>For Login, we need the following data</p>
        {error && <Error msg={error} />}
    </div>
    <form onSubmit={handleSubmit}>
        <div className="div-input">
            <label htmlFor='email'>Email address or phone number</label>
            <input id="email" value={email} onChange={e => setEmail(e.target.value)} type="text" />
        </div>
        <div className="div-input">
            <label htmlFor='password'>Password</label>
            <input id="password" value={password} onChange={e => setPassword(e.target.value)} type="password" />
        </div>
        <div className="text-center mb-3">
            <Link className='forgot' to='/forgotPassword'>Forgot Password ?</Link>
        </div>
        <button className='login-btn btn btn-primary w-100 mb-3'>Login</button>
    </form>
    <div className="text-center">
        <Link to='/register'><button className='create-btn btn btn-primary w-100'>Create Account</button></Link>
    </div>
</div>

    </div>
  )
}

export default Login;
