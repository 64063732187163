import axios from 'axios';
import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useUserAuth } from '../context/userContext';

const Members = () => {
    const {user} = useUserAuth()
    const [members,setMembers] = useState([]);
    const [disableBtn,setDisableBtn] = useState(false);
    const [itemsNo, setItemsNo] = useState(8);
    const [count,setCount] = useState(0);
    useEffect(() =>{
        fetchAllMembers(count);
    },[count])
    const fetchAllMembers =async()=>{
        // if(firstTime) setCount(0)
        // console.log(members,'mems');
        // setFiltered([]);
// console.log(itemsNo,'itemsNo from fetch memebr');
console.log(count,'count from fetch memebr');
        await axios.get('https://sarcbackend-8f58e8101861.herokuapp.com/members',{
            headers:{
                "auth": user.accessToken,
                "itemsNo": itemsNo,
                "skip": (count*itemsNo)
            }
        }).then(res=>{
            console.log(res,'members');
            // const data = await res.json();
            // setFiltered([])
            if(res.data && res.data.length === 0) setDisableBtn(true)
            setMembers(prev=>[...prev,...res.data])
        })
        .catch(err => {
            console.log(err);
            console.log(err.response.status,'status');
            if(err.response.status === 453){
                let refresh = window.location.href;
                window.location.href = refresh;
            }
        })
        }
  return (
    <table className='table-users'>
    <thead>
        <tr>
        <th>Full Name</th>
        <th>Application status</th>
        <th>Phone number</th>
        <th>Email address</th>
        <th>Attendee/speaker</th>
        <th className='view'>Application</th>
        </tr>
    </thead>
  {members && members.length > 0 && <tbody>
      {/* aaallllll mmebers */}
        {members.map((mem,ind) =>
        <tr key={ind}>
        <td className='name'>{mem.name}</td>
        <td><p className={`status ${mem.AppStatus && mem.AppStatus === 'Approved' ? 'green':mem.AppStatus === 'Rejected' ? 'red' : ''}`}>{mem.AppStatus}</p></td>
        <td>+ {mem.phone}</td>
        <td className='email'>{mem.email}</td>
        <td className='type'>{mem.participateAs}</td>
        <td className='view'><Link to={`/sarc3/manage/${mem._id.toString()}`}>View</Link></td>
    </tr>
        )}
        <tr className='row-btn'>
            <td colSpan="6"><button disabled={disableBtn} onClick={() => setCount(count + 1)}>Load More</button></td>

        </tr>
    </tbody>}
</table>
  )
}

export default Members