import React from 'react';

function Partner() {
  return (
    <div className='container d-flex justify-content-center mb-5'>
      <div className='row '>
        <h1 className='mb-5 text-center'>Our Main Partner</h1>
        <div className=" mb-3 mt-2 d-flex justify-content-center " style={{backgroundColor:'#38b6ff'}}>
          <a href="/partner" target='_blank' className='d-flex justify-content-cente'>
            <img src="/assets/partnerlogo.png" alt='sponsor' className='img-fluid' style={{maxWidth:'500px'}}/>
          </a>
        </div>
       
      </div>
    </div>
  );
}

export default Partner;
