import React from 'react'

export const OutcomeGoals = () => {
  return (
    <div className="outcome-goals">
      <h2 className="head-title">Outcome</h2>
     <ul className="list">
     <li className="content">Autism which will further expand the understanding in this field Activity.</li>
      <li className="content">Present research projects in the field of autism spectrum disorder regionally. </li>
      <li className="content">Encourage young researches to present and embrace more in scientific re search.</li>
      <li className="content">Build a research forum to improve outcomes of national and regional autism research.</li>
      <li className="content">Build a national and regional database for a autism and related disorders research.</li>
     </ul>
     <div className="transparent">
      <img src="/assets/sarctransparent.png" alt='sarctransparent'/>
     </div>

     <h2 className="head-title">Goals</h2>
     <ul className="list">
     <li className="content">Improve the quantity of research initiatives in ASD</li>
      <li className="content">Motives researchers to advance and improve quality of their research projects</li>
      <li className="content">Encourage new innovations and e-health in the field of ASD</li>
      <li className="content">provide venue to improve skills of young researchers in the field</li>
     </ul>
     </div>
  )
}
