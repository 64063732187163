import React, { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Error } from "../Components/Error";
import { getAuth, signOut } from "firebase/auth";
import { useUserAuth } from "../context/userContext";
import "../css/profile.css";
import { Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import jsPDF from "jspdf";
import img from "../assets/certificate-background.png";
const Profile = () => {
  const navigate = useNavigate();
  const { setIsAdmin, isAdmin, user, userDetails } = useUserAuth();
  const { id } = useParams();
  const [apps, setApps] = useState([]);
  const [profInfo, setProfInfo] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [count, setCount] = useState(0);
  const [disableBtn, setDisableBtn] = useState(0);
  const [itemsNo, setItemsNo] = useState(3);
  const [SCFHSNo, setSCFHSNo] = useState("");
  const [parcNo, setParcNo] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [workshops, setWorkshops] = useState([]);
  const [showApplications, setShowApplications] = useState(true);
  const [showWorkshops, setShowWorkshops] = useState(false);

  useEffect(() => {
    if (userDetails) {
      setEmail(userDetails.email || "");
      setName(userDetails.name || "");
      setParcNo(userDetails.parcNo || "");
      setSCFHSNo(userDetails.SCFHSNo || "");
      setPhone(userDetails.phone || "");
    }
  }, [userDetails]);

  useEffect(() => {
    fetchUserApplications();
  }, [count]);

  const clickEditBtn = () => {
    setProfInfo(true);
    setEditMode(true);
  };

  const [isPaid, setIsPaid] = useState(false);

  const fetchUserApplications = async () => {
    try {
      const res = await axios.get(
        "https://sarcbackend-8f58e8101861.herokuapp.com/userApplications",
        {
          headers: {
            auth: user.accessToken,
            memberId: id,
            itemsNo: itemsNo,
            skip: count * 3,
          },
        }
      );

      if (res.data && res.data.length === 0) {
        setDisableBtn(true);
      }

      // Check if the paymentstatus of the first application is "paid"
      const paid = res.data.length > 0 && res.data[0].paymentstatus === "paid";

      // Set isPaid based on whether the paymentstatus is "paid"
      setIsPaid(paid);

      setApps(res.data);
      setWorkshops(res.data[0]?.workshop || []);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 453) {
        let refresh = window.location.href;
        window.location.href = refresh;
      }
    }
  };

  const updateProfile = async () => {
    if (!email || !name || !phone) {
      setError("Data must be filled");
      setTimeout(() => setError(""), 4000);
      return;
    }
    try {
      const res = await axios.post(
        "https://sarcbackend-8f58e8101861.herokuapp.com/editProfile",
        {
          email,
          phone,
          name,
          parcNo,
          SCFHSNo,
        },
        {
          headers: {
            auth: user.accessToken,
          },
        }
      );
      console.log(res);
      setEditMode(false);
      let refresh = window.location.href;
      window.location.href = refresh;
    } catch (error) {
      console.log(error);
      if (error.response?.status === 453) {
        let refresh = window.location.href;
        window.location.href = refresh;
      }
      if (error.response?.status === 456) {
        setError("Email already exist.");
        setTimeout(() => setError(""), 4000);
      }
    }
  };

  const auth = getAuth();

  const handleLogout = async () => {
    isAdmin && setIsAdmin(false);
    signOut(auth);
    window.location.href = "/login";
  };
  const handlePayNow = () => {
    toast.info("Payment will be avliable soon");
  };

  const generateCertificate = (course) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Add background image
    doc.addImage(
      img,
      "PNG",
      0,
      0,
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getHeight()
    );

    // Add recipient name
    doc.setFontSize(30);
    doc.setFont("helvetica"); // Change the font family and style
    doc.text(name, 35, 130, { align: "left" }); // put the nick name

    // Add course name
    doc.setFontSize(20);
    doc.text("", 105, 195, { align: "center" }); // put the course name

    // Save the PDF
    doc.save(`${name}-${"certificate"}.pdf`);
  };

  return (
    <div className="profile-page">
      {/* {JSON.stringify(user)} */}
      <div className="container">
        <Header
          smallNav={
            <div className="user-icon-sm">
              <p>{userDetails && userDetails.name?.slice(0, 2)}</p>
            </div>
          }
        ></Header>
      </div>
      <div className="back-title">
        <div className="container">
          <div className="title-edit">
            <div className="title-arrow">
              <div
                className="arrow"
                style={{ visibility: editMode ? "hidden" : "visible" }}
              >
                <i
                  className={`fa-solid fa-angle-${profInfo ? "down" : "up"}`}
                  onClick={() => setProfInfo(!profInfo)}
                ></i>
              </div>
              <div className="text">
                <h2>Profile information</h2>
                <p className="desc">
                  Keep your personal information up to date
                </p>
              </div>
            </div>
            <div className="edit-btn">
              {!editMode ? (
                <button className="edit" onClick={clickEditBtn}>
                  <i className="fa-solid fa-pen"></i>Edit
                </button>
              ) : (
                <>
                  <button className="edit-save" onClick={updateProfile}>
                    Save
                  </button>
                  <button
                    className="edit-cancel"
                    onClick={() => setEditMode(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {!editMode ? (
          <div className={`profile-info ${profInfo ? "show" : ""}`}>
            <div className="info">
              <p className="head">Full name</p>
              <p className="value">{name ? name : "---"}</p>
            </div>
            <div className="info">
              <p className="head">Email address</p>
              <p className="value">{email ? email : "---"}</p>
            </div>
            <div className="info">
              <p className="head">Phone number</p>
              <p className="value">{phone ? phone : "---"}</p>
            </div>
            <div className="info">
              <p className="head">Parc number</p>
              <p className="value">{parcNo ? parcNo : "---"}</p>
            </div>
            <div className="info">
              <p className="head">SCFHS number</p>
              <p className="value">{SCFHSNo ? SCFHSNo : "---"}</p>
            </div>
          </div>
        ) : (
          <div className="edit-mode">
            <form>
              {error && <Error msg={error} />}
              <div className="info">
                <label className="head" htmlFor="fullname">
                  Full name*
                </label>
                <input
                  id="fullname"
                  className="value"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="info">
                <label className="head" htmlFor="email">
                  Email address*
                </label>
                <input
                  id="email"
                  className="value"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </div>
              <div className="info">
                <label className="head" htmlFor="phone">
                  Phone Number*
                </label>
                <input
                  id="phone"
                  className="value"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="number"
                />
              </div>
              <div className="info">
                <label className="head" htmlFor="parc">
                  Parc Number
                </label>
                <input
                  id="parc"
                  className="value"
                  value={parcNo}
                  onChange={(e) => setParcNo(e.target.value)}
                  type="text"
                />
              </div>
              <div className="info">
                <label className="head" htmlFor="scfhs">
                  SCFHS Number
                </label>
                <input
                  id="scfhs"
                  className="value"
                  value={SCFHSNo}
                  onChange={(e) => setSCFHSNo(e.target.value)}
                  type="text"
                />
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="back-title">
        <div className="container">
          <div className="title-edit">
            <div className="title-arrow">
              <div className="arrow">
                <i
                  onClick={() => setShowApplications(!showApplications)}
                  className={`fa-solid fa-angle-${
                    showApplications ? "down" : "up"
                  }`}
                ></i>
              </div>
              <div className="text">
                <h2>Applications</h2>
                <p className="desc">Your all requests</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showApplications && (
        <div className="container">
          <div className={`margin-apps ${showApplications ? "show" : ""}`}>
            <table className="table-users">
              <thead>
                <tr>
                  <th className="table-header">Full Name</th>
                  <th className="table-header">Application status</th>
                  <th className="table-header">Phone number</th>
                  <th className="table-header">Email address</th>
                  <th className="table-header">Attendee/speaker</th>
                </tr>
              </thead>
              {apps && apps.length > 0 && (
                <tbody>
                  {apps.map((mem, ind) => (
                    <tr key={ind} className="table-row">
                      <td className="table-data">{mem.name}</td>
                      <td className="table-data">
                        <p
                          className={`status ${
                            mem.AppStatus && mem.AppStatus === "Approved"
                              ? "green"
                              : mem.AppStatus === "Rejected"
                              ? "red"
                              : ""
                          }`}
                        >
                          {mem.AppStatus}
                        </p>
                      </td>
                      <td className="table-data">+ {mem.phone}</td>
                      <td className="table-data">{mem.email}</td>
                      <td className="table-data">{mem.participateAs}</td>
                    </tr>
                  ))}
                  <tr className="row-btn">
                    <td colSpan="6">
                      <button
                        disabled={disableBtn}
                        onClick={() => setCount(count + 1)}
                      >
                        Load More
                      </button>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
      <div className="back-title">
        <div className="container">
          <div className="title-edit">
            <div className="title-arrow">
              <div className="arrow">
                <i
                  onClick={() => setShowWorkshops(!showWorkshops)}
                  className={`fa-solid fa-angle-${
                    showWorkshops ? "down" : "up"
                  }`}
                ></i>
              </div>
              <div className="text mb-5">
                <div className="row">
                  <div className="col">
                    <h4>Workshops And MainConference </h4>
                  </div>
                  <div className="col-auto ms-auto">
                    <small
                      onClick={() => navigate("/sarc3/attendee")}
                      className="btn btn-danger float-end ms-auto "
                    >
                      Edit
                    </small>
                  </div>

                  {isPaid && (
                    <div className="col-auto ms-auto">
                      <small
                        onClick={() => generateCertificate(user.name)}
                        className="btn btn-success float-end ms-auto "
                      >
                        Download Certificate
                      </small>
                    </div>
                  )}
                </div>
                <p className="desc">Your workshop registrations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWorkshops && (
        <div className="container mb-5" style={{ marginBottom: "150px" }}>
          <div className={` ${showWorkshops ? "show" : ""}`}>
            <table className="table-workshops">
              <thead>
                <tr>
                  <th className="table-header">Title</th>
                  <th className="table-header">Time</th>
                  <th className="table-header">Speaker</th>
                  <th className="table-header">Status</th>
                  <th className="table-header">Price</th>
                  <th className="table-header">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Render the default conference option */}
                <tr key="conference" className="table-row">
                  <td className="table-data">Main Conference</td>
                  <td className="table-data">10:00 - 17:00</td>
                  <td className="table-data">Various Speakers</td>
                  <td className="table-data">
                    {isPaid === true ? "paid" : "unPaid"}
                  </td>
                  <td className="table-data">450</td>
                  <td className="table-data">
                    {" "}
                    <button className="btn btn-primary">Pay Now</button>
                  </td>
                </tr>

                {/* Render other workshops */}
                {workshops.map((workshop, index) => (
                  <tr key={index} className="table-row">
                    <td className="table-data">{workshop.title}</td>
                    <td className="table-data">{workshop.time}</td>
                    <td className="table-data">{workshop.speaker}</td>
                    <td className="table-data">
                      {isPaid === true ? "paid" : "unPaid"}
                    </td>
                    <td className="table-data">{workshop.price}</td>
                    <td className="table-data">
                      {isPaid === false && (
                        <button
                          className="btn btn-primary"
                          onClick={() => handlePayNow(workshop)}
                        >
                          Pay Now
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="table-footer" colSpan="4">
                    Total
                  </td>
                  <td className="table-footer">
                    {/* Calculate and render the total price */}
                    <span>
                      {workshops.reduce(
                        (total, workshop) => total + workshop.price,
                        450
                      )}
                    </span>{" "}
                    <span></span>
                  </td>
                  <td>
                    <button className="btn btn-primary">Pay Total Now</button>
                  </td>
                  <td className="table-footer"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
