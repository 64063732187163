import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Table, Button, Form, Modal } from "react-bootstrap";
import "../css/manage.css";
import { Header } from "../Components/Header";
import CustomModal from "../Components/CustomModal";
import CustomModal2 from "../Components/CustomModal2";
import CustomModal3 from "../Components/CustomModal3";
import { useUserAuth } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function ManageApps() {
  const [allApplications, setAllApplications] = useState([]);
  const [selectedWorkshops, setSelectedWorkshops] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [excelData, setExcelData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { setIsAdmin, isAdmin, user, userDetails } = useUserAuth();
  const [paymentUrl, setPaymentUrl] = useState("");
  const [exportEvent, setExportEvent] = useState(false);
  const [filterStatus, setFilterStatus] = useState(""); // State for filter status (paid or unpaid)

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("");

  const [showModal2, setShowModal2] = useState(false);
  const handleSendBill = (applicationId, paymentUrl) => {
    setPaymentUrl(paymentUrl);
    // Implement logic to send the bill with the provided payment URL
    console.log(`Sending bill with payment URL: ${paymentUrl}`);
    setSelectedId(applicationId);
    // Close the modal after sending the bill
    setShowModal2(true);
  };


  const fetchApplicationsByStatus = async (status) => {
    try {
      const res = await axios.get(
        `https://sarcbackend-8f58e8101861.herokuapp.com/applicationsByStatus/${status}`
        , {
            headers: {
              "auth": user.accessToken,
            
          }
          }
      );
      setAllApplications(res.data);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    if (searchTerm === "") {
      if (filterStatus === "paid" || filterStatus === "unpaid") {
        fetchApplicationsByStatus(filterStatus);
      } else {
        fetchAllApplications();
      }
    } else {
      fetchAllApplicationsWithoutPagination();
    }
  }, [currentPage, searchTerm, filterStatus]);

  const exportPaidToExcel = async () => {
    try {
      const res = await axios.get(
        `https://sarcbackend-8f58e8101861.herokuapp.com/allApplicationsWithoutArchivedPaid`
      );

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = "Paid_applications_data";
      const exportData = res?.data
        .map((application, index) => {
          const workshops = application.workshop.map((ws, idx) => ({
            SN: index + 1,
            WorkshopNumber: idx + 1,
            Name: application.name,
            Email: application.email,
            Phone: application.phone,
            // Include workshop details
            SCFHSNo:application.SCFHSNo,
            WorkshopTitle: ws.title,
            WorkshopTime: ws.time,
            WorkshopSpeaker: ws.speaker,
            // Add more fields from the application object
            //   _id: application._id,
            participateAs: application.participateAs,
            parcNo: application.parcNo,
            paymentstatus: application.paymentstatus,
            paymentUrl: application.paymentUrl,
          }));
          return [application, ...workshops];
        })
        .flatMap((entry) => entry);

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.log(error);
    }
  };

  const exportToExcel = async () => {
    try {
      const res = await axios.get(
        `https://sarcbackend-8f58e8101861.herokuapp.com/allApplicationsWithoutArchived`
      );

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = "applications_data";
      const exportData = res?.data
        .map((application, index) => {
          const workshops = application.workshop.map((ws, idx) => ({
            SN: index + 1,
            WorkshopNumber: idx + 1,
            Name: application.name,
            Email: application.email,
            Phone: application.phone,
            SCFHSNo:application.SCFHSNo,
            // Include workshop details
            WorkshopTitle: ws.title,
            WorkshopTime: ws.time,
            WorkshopSpeaker: ws.speaker,
          
            // Add more fields from the application object
            //   _id: application._id,
            participateAs: application.participateAs,
            parcNo: application.parcNo,
            paymentstatus: application.paymentstatus,
            paymentUrl: application.paymentUrl,
          }));
          return [application, ...workshops];
        })
        .flatMap((entry) => entry);

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllApplicationsWithoutPagination = async () => {
    try {
      const res = await axios.get(
        `https://sarcbackend-8f58e8101861.herokuapp.com/allApplicationsWithoutArchived`
      );
      setAllApplications(res.data);
      setExcelData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredApplications = allApplications.filter((application) =>
    application.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const fetchAllApplications = async () => {
    try {
      const res = await axios.get(
        `https://sarcbackend-8f58e8101861.herokuapp.com/allApplications?page=${currentPage}`
      );
      setAllApplications(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatusChange = async (applicationId, status) => {
    try {
      // Make an API call to update the status
      await axios.put(
        `https://sarcbackend-8f58e8101861.herokuapp.com/updatePaymentStatus/${applicationId}`,
        { paymentstatus: status },
        {
          headers: {
            auth: user.accessToken,
          },
        }
      );

      // If the status is updated successfully, alert the user
      alert(`Status updated successfully for application ${applicationId}`);
      // Refetch all applications to update the UI
      fetchAllApplications();
    } catch (error) {
      console.log(error);
      // If there's an error, alert the user
      alert(`Failed to update status for application ${applicationId}`);
    }
  };

  // Function to toggle display of workshops modal
  const toggleModal = (workshops) => {
    setSelectedWorkshops(workshops);
    setShowModal(!showModal);
  };

  const sendBill = async (applicationId) => {
    console.log(`Sending bill for application ${applicationId}`);
    try {
      //  an API call to update the status
      await axios.put(
        `https://sarcbackend-8f58e8101861.herokuapp.com/updatePaymentUrl/${selectedId}`,
        { paymentUrl: paymentUrl },
        {
          headers: {
            auth: user.accessToken,
          },
        }
      );
      alert(`url  updated successfully for application ${selectedId}`);
      fetchAllApplications();
    } catch (error) {
      console.log(error);
      alert(`Failed to update payment url for application ${applicationId}`);
    }
    setShowModal2(true);
  };
  const [deletModalShow, setDeletModalShow] = useState(false);
  const [appemail, setAppEmail] = useState("");
  const [deleteApp, setDeletApp] = useState("");
  const deleteApplication = (applicationId, email) => {
    // Implement logic to delete the application with ID: applicationId
    console.log(`Deleting application ${applicationId}`);
    setDeletModalShow(true);
    setAppEmail(email);
    setDeletApp(applicationId);
  };

  // Function to calculate total amount for an application
  const calculateTotalAmount = (workshops) => {
    let total = 450; // Main conference fee
    if (workshops) {
      total += workshops.reduce(
        (acc, workshop) => acc + (workshop.price || 0),
        0
      );
    }
    return total;
  };

  // Function to get total status based on workshop statuses
  const getTotalStatus = (workshops) => {
    if (!workshops || workshops.length === 0) {
      return "unpaid"; // Default to unpaid if no workshops
    }

    const workshopStatuses = workshops.map((workshop) => workshop.status);
    return workshopStatuses.every((status) => status === "unpaid")
      ? "unpaid"
      : "unpaid";
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleUpdateApplications = async () => {
    try {
      await axios.put(
        "https://sarcbackend-8f58e8101861.herokuapp.com/updateSarc3"
      );
      alert("Applications updated successfully");
    } catch (error) {
      console.log(error);
      alert("Failed to update applications");
    }
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const archiveApplication = async () => {
    try {
      // Make an API call to archive the application
      await axios.put(
        `https://sarcbackend-8f58e8101861.herokuapp.com/archiveApplication/${deleteApp}`,
        {},
        {
          headers: {
            auth: user.accessToken,
          },
        }
      );
      alert(`Application with ID ${deleteApp} archived successfully`);
      fetchAllApplications(); // Refetch all applications to update the UI
    } catch (error) {
      console.log(error);
      alert(`Failed to archive application with ID ${deleteApp}`);
    }
    setDeletModalShow(false); // Close the delete modal
  };

  const handleEdit = (email) => {
    // fetchUserApplications()
    navigate(`/sarc3/attendee/edit/${email}`);
  };
  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const resetFilters = () => {
    setSearchTerm("");
    setFilterStatus("");
    fetchAllApplications();
  };

  return (
    <div className="m-5">
      <Header />
      <h1 className="text-center mt-4 mb-4">Applications Management </h1>
      <div className="table-responsive">
        <div className="row">
        <div className="col-md-4">
    <Form className="mb-4">
      <Form.Group controlId="searchEmail">
        <Form.Control
          type="text"
          placeholder="Search by email"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Form.Group>
    </Form>
  </div>
  <div className="col-md-auto">
    <div className="row">
        <div className="col-auto">
             <Form.Check
      type="radio"
      label="Paid"
      value="paid"
      checked={filterStatus === "paid"}
      onChange={handleFilterChange}
      className="me-2"
    />
        </div><div className="col-1">

        <Form.Check
      type="radio"
      label="Unpaid"
      value="unpaid"
      checked={filterStatus === "unpaid"}
      onChange={handleFilterChange}
    />
        </div>
    </div>
   
   
  </div>
  <div className="col-md-2">
    <Button className=" mb-4" onClick={resetFilters}>
      Reset
    </Button>
  </div>
          <div className="col-auto col-md-2 ">
            <Button className="mb-4 btn btn-success" onClick={exportToExcel}>
              Export Excel
            </Button>
          </div>

          <div className="col-auto col-md-2 ">
            <Button className="mb-4 btn btn-warning" onClick={exportPaidToExcel}>
              Export Paid Excel
            </Button>
          </div>
        </div>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>SCFHSNo</th>
              <th>Workshops</th>
              <th>Total Amount</th> {/* New column for total amount */}
              <th>Participate As</th>
              <th>Application Status</th>
              <th>Parc No</th>
             
              <th>Conference</th>
              <th>Bill Sent</th>
              <th className="text-center" colSpan={2}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredApplications?.map((application) => (
              <tr key={application._id}>
                <td>{application.name}</td>
                <td>{application.email}</td>
                <td>{application.phone}</td>
                 <td>{application.SCFHSNo}</td>
                <td>
                  {application.workshop?.length > 0 ? (
                    <Button
                      variant="link"
                      onClick={() => toggleModal(application.workshop)}
                    >
                      Show Workshops
                    </Button>
                  ) : (
                    "--"
                  )}
                </td>
                <td>{calculateTotalAmount(application.workshop)}</td>
                <td>{application.participateAs}</td>
                <td>
                  {" "}
                  {/* Application Status */}
                  <Form.Select
                    className="status-dropdown"
                    aria-label="Application Status"
                    value={application.paymentstatus}
                    onChange={(e) =>
                      handleStatusChange(application._id, e.target.value)
                    }
                  >
                    <option value="unpaid">Unpaid</option>
                    <option value="paid">Paid</option>
                    <option value="cancelled">Cancelled</option>
                  </Form.Select>
                </td>
                <td>{application.parcNo || "--"}</td>
                <td>Subscribed</td> {/* Default value for Conference */}
                <td>
                  {application.paymentUrl == "none" ||
                  application.paymentUrl === "" ? (
                    <b className="text-danger">{"false"}</b>
                  ) : (
                    <b className="text-primary">{"true"}</b>
                  )}
                </td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleSendBill(application._id, application.paymentUrl)
                    }
                  >
                    Send Bill
                  </Button>
                  <Button
                    className="mt-3"
                    variant="danger"
                    onClick={() =>
                      deleteApplication(application._id, application.email)
                    }
                  >
                    Delete
                  </Button>
                </td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleEdit(application.email)}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <CustomModal2
        isOpen={showModal2}
        onClose={() => setShowModal2(false)}
        onSendBill={sendBill}
        paymentUrl={paymentUrl}
        setPaymentUrl={setPaymentUrl}
      />
      <CustomModal3
        isOpen={deletModalShow}
        onClose={() => setDeletModalShow(false)}
        appemail={appemail}
        onDelete={archiveApplication}
      />

      {/* Pagination */}
      {searchTerm === ""&&filterStatus==="" && (
        <div className="pagination container">
          <Button
            className="w-50"
            variant="primary"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="mx-3">Page {currentPage}</span>
          <Button
            variant="primary"
            className="w-50"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </div>
      )}

      {/* Update Applications Button */}
      {/* `<div className="update-applications-container">
                <Button variant="info" onClick={handleUpdateApplications}>Update Applications</Button>
            </div>` */}

      {/* Workshop Modal */}
      <CustomModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        workshops={selectedWorkshops}
      />
    </div>
  );
}

export default ManageApps;
