import React from 'react';
import '../css/sponsers.css'
function Sponsors() {
  return (
    <div className='container mb-5'>
      <div className='row mb-5 p-0'>
    <h1 className='mb-5 text-center'>Sponsors</h1>
    <div className="col-md-12 p-0 col-sm-12 mb-3 mt-5 d-flex justify-content-center">
        <div className="sponsor-card">
            <a href="https://namaee.com/" target='_blank'>
                <img src="/assets/sponser1.jpeg" alt='sponsor' className="sponsor-image" />
            </a>
        </div>
    </div>
    <div className="col-md-6 p-0 col-sm-12 mt-5 d-flex justify-content-center">
        <div className="sponsor-card">
            <a href="https://www.nestle.com" target='_blank'>
                <img src="/assets/nastleh.png" alt='sponsor' className="sponsor-image" />
            </a>
        </div>
    </div>
    <div className="col-md-6 p-0 col-sm-12 mt-5 d-flex justify-content-center">
        <div className="sponsor-card">
            <a href="https://www.wyethnutrition.com/" target='_blank'>
                <img src="/assets/wyeth.png" alt='sponsor' className="sponsor-image" />
            </a>
        </div>
    </div>
</div>

    </div>
  );
}

export default Sponsors;
