import React from 'react';
import '../css/MainConference.css'; 
import { Card } from 'react-bootstrap';
import { useUserAuth } from '../context/userContext';

const MainConferenceComponent = () => {

  const {user,isAdmin} = useUserAuth();
    const conferenceSchedule = [
        {
          day: "DAY 1",
          date: "Tuesday 23rd April 2024",
          sessions: [
            {
              sessionNum: "Session (1)",
              moderator: "Moderator: Dr. Faisal Alnemry (S)",
              events: [
                {
                  time: "8:00",
                  title: "Registration",
                  speaker: ""
                },
                {
                  time: "8:30 - 9:00",
                  title: "Opening Ceremony",
                  speaker: ""
                },
                {
                  time: "9:00 - 9:35",
                  title: "Pivotal Response treatment: Research Update on Pivotal Response Treatment for Autism.",
                  speaker: "Dr. Antonio Hardan (K)"
                },
                {
                  time: "9:35 - 10:05",
                  title: "Exploring molecular pathways associated with autism using cell-based models and genomics approaches.",
                  speaker: "Dr. Lawrence Stanton (K)"
                },
                {
                  time: "10:05 - 10:35",
                  title: "The power of networks in autism research",
                  speaker: "Dr. Mayada Elsabbagh (K)"
                },
                {
                  time: "10:35 - 11:05",
                  title: "Parent-Therapist Dynamic Collaboration",
                  speaker: "Dr. Asmahan Saleh Khalil (K)"
                },
                {
                  time: "11:05 - 11:15",
                  title: "Coffee Break",
                  speaker: ""
                },
                {
                  time: "11:15 - 11:35",
                  title: "A Systematic Review of Augmented Reality-Aided (ARA) Reading Interventions for Students with Autism Spectrum Disorder (ASD)",
                  speaker: "Ms. Farah Al Zain (A)"
                },
                {
                  time: "11:35 - 11:55",
                  title: "Understanding Adolescence and Adults with Autism in Arab Countries",
                  speaker: "Ms. Alain Yammine (A)"
                },
                {
                  time: "11:55 - 12:15",
                  title: "Autistic Adolescents in Saudi Arabia: Self-Determination and Environment",
                  speaker: "Mr. Saleh Aba Alkhayl (A)"
                },
                {
                  time: "12:15 - 12:30",
                  title: "Discussion",
                  speaker: "All"
                },
                {
                  time: "12:30 - 13:15",
                  title: "Lunch and prayer",
                  speaker: ""
                },
              ]
            },
            {
              sessionNum: "Session (2)",
              moderator: "Moderator: Dr. Aiiat Alwahid (S)",
              events: [
                {
                  time: "13:15 - 13:45",
                  title: "Assessment in autism: Novel Instruments to Assess Core Features of Autism",
                  speaker: "Dr. Antonio Hardan (K)"
                },
                {
                  time: "13:45 - 14:15",
                  title: "Latest research on sexual health education in the Arab world",
                  speaker: "Dr. Asmahan Saleh Khalil (K)"
                },
                {
                  time: "14:15 - 14:45",
                  title: "The WHO Caregiver Skills Training Program",
                  speaker: "Dr. Mayada Elsabbagh (K)"
                },
                {
                  time: "14:45 - 15:05",
                  title: "The reality of applying evidence-based behavioral practices with individuals with autism spectrum disorder.",
                  speaker: "Ms.Afnan Majed Alsaeidi (A)"
                },
                {
                  time: "15:05 - 15:25",
                  title: "Services of Applied Behavior Analysis in Saudi Arabia: Challenges and Needs",
                  speaker: "Mr. Abdullah Alotaibi (A)"
                },
                {
                  time: "15:25 - 15:45",
                  title: "The Emergence of Naming Repertoire in a Child Diagnosed with Autism as a Function of Multiple Exemplar instruction",
                  speaker: "Ms. Esraa Douran (A)"
                },
                {
                  time: "15:45 - 16:00",
                  title: "Discussion",
                  speaker: "All"
                },
              ]
            }
          ]
        },
        {
          day: "DAY 2",
          date: "Wednesday 24th April 2024",
          sessions: [
            {
              sessionNum: "Session (3)",
              moderator: "Moderator: Dr. Amel Alawami (S)",
              events: [
                {
                  time: "8:30",
                  title: "Registration",
                  speaker: ""
                },
                {
                  time: "9:00 - 9:35",
                  title: "Care for people with ASD in resource-challenged settings",
                  speaker: "Prof. Gaziudin (K)"
                },
                {
                  time: "9:35 - 10:05",
                  title: "Developing an Objective Autism Risk Index Using Arabic Remote Eye Tracking Paradigm for the Early Screening and Diagnosis of Autism Spectrum Disorder",
                  speaker: "Dr. Fouad Alshaban (K)"
                },
                {
                  time: "10:05 - 10:25",
                  title: "Compassionate and Ethics in building an autism supportive institution",
                  speaker: "Dr. Noor Syed (K)"
                },
                {
                  time: "10:25 - 10:45",
                  title: "Planning tools to support effective transition to adulthood for individuals with autism",
                  speaker: "Dr. Nasiah Cirincione (K)"
                },
                {
                  time: "10:45 - 11:00",
                  title: "Coffee Break",
                  speaker: ""
                },
                {
                  time: "11:00 - 11:20",
                  title: "Complementary Therapy in ASD: - Efficacy and Rationale",
                  speaker: "Dr. Basma Al Jabri (S)"
                },
                {
                  time: "11:20 - 11:40",
                  title: "The Effects of Speaker Immersion on Independent Speaker Behavior in non-instructional Settings",
                  speaker: "Ms. Samah Khashoggi (A)"
                },
                {
                  time: "11:40 - 12:00",
                  title: "Views and Responses of Students with Autism Spectrum Disorder and Their Families on Services and Support from Higher Education Institutions",
                  speaker: "Ms. Asmaa AlZahrani (A)"
                },
                {
                  time: "12:00 - 12:20",
                  title: "A Review of Behavioral Interventions Used to Improve Menstrual Hygiene Independence in Young Women with Developmental Disabilities",
                  speaker: "Ms. Sarah Alzahrani (A)"
                },
                {
                  time: "12:20 - 12:30",
                  title: "Discussion",
                  speaker: "All"
                },
                {
                  time: "12:30 - 13:15",
                  title: "Lunch and prayer",
                  speaker: ""
                },
              ]
            },
            {
              sessionNum: "Session (4)",
              moderator: "Moderator: Dr.Elham Alhifithy (S)",
              events: [
                {
                  time: "13:15 - 13:45",
                  title: "Autism in Adults: The coming epidemic!",
                  speaker: "Prof. Gaziudin (K)"
                },
                {
                  time: "13:45 - 14:10",
                  title: "Update on Saudi national screening program",
                  speaker: "Dr.Saleh Alsalehi (S)"
                },
                {
                  time: "14:10 - 14:30",
                  title: "Profound Autism Research: Current Trends and Emerging Insights",
                  speaker: "Dr. Anja Jelaska (A)"
                },
                {
                  time: "14:30 - 14:50",
                  title: "Autism and sexually related issues: are they addressed in Arab culture?",
                  speaker: "Dr. Shahad Al Kahlifa(A)"
                },
                {
                  time: "14:50 - 15:10",
                  title: "Healthcare utilisation in Saudi Arabia for children with Autism Spectrum Disorder and comorbidities.",
                  speaker: "Dr.Ahmad M Almai (A)"
                },
                {
                  time: "15:10 - 15:30",
                  title: "Efficacy of Play-Based Interventions for Children with ASD",
                  speaker: "Ms. Rozan El-Khateeb (A)"
                },
                {
                  time: "15:30 - 15:50",
                  title: "Increase Acceptance of Cutting Nails with Learners with Autism Spectrum Disorder using Behavioral Strategies",
                  speaker: "Ms.Sana Ibrahim (A)"
                },
                {
                  time: "15:50 - 16:00",
                  title: "Discussion",
                  speaker: "All"
                },
              ]
            }
          ]
        }
      ];
      
      const isMobile = window.innerWidth <= 600;


      return (
        <div className="conference-container">
          {conferenceSchedule.map((day, dayIndex) => (
            <div key={dayIndex}>
              <h2>{day.day}</h2>
              <h3>{day.date}</h3>
              {day.sessions.map((session, sessionIndex) => (
                <div key={sessionIndex} className="session-container">
                  <h4>
                    {session.sessionNum} - {session.moderator}
                    {isAdmin && <button>Edit</button>}
                  </h4>
                  <div className="conference-content">
                    {isMobile ? (
                      session.events.map((event, eventIndex) => (
                        <Card key={eventIndex} className="conference-card">
                          <Card.Body>
                            <Card.Title>{event.title}</Card.Title>
                            <Card.Text>
                              <strong>Time:</strong> {event.time}
                              <br />
                              <strong>Speaker:</strong> {event.speaker}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      ))
                    ) : (
                      <table className="conference-table">
                        <thead>
                          <tr>
                            <th>Time</th>
                            <th>Topic</th>
                            <th>Speaker</th>
                            {isAdmin && <th>Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {session.events.map((event, eventIndex) => (
                            <tr key={eventIndex}>
                              <td>{event.time}</td>
                              <td>{event.title}</td>
                              <td>{event.speaker}</td>
                              {isAdmin && (
                                <td>
                                  <button>Edit</button>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
};

export default MainConferenceComponent;
