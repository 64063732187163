import React, { useState } from 'react';
import '../css/customModal.css';


function CustomModal2({ isOpen, onClose, onSendBill,setPaymentUrl,paymentUrl }) {
 


    const modalClassName = isOpen ? 'custom-modal show' : 'custom-modal';

    const handleSendBill = () => {
        onSendBill(paymentUrl);
        onClose();
    };

    return (
        <div className={modalClassName}>
            <div className="custom-modal-content">
                <span className="close-btn" onClick={onClose}>&times;</span>
                <div className="modal-body">
                    
                    <label htmlFor="paymentUrl">Payment URL:</label>
                    <input
                        type="text"
                        id="paymentUrl"
                        value={paymentUrl}
                        onChange={(e) => setPaymentUrl(e.target.value)}
                        className='form-control'
                    />
                </div>
                <div className="modal-footer">
                    <button className="btn btn-primary" onClick={handleSendBill}>Send Bill</button>
                    <button className="btn btn-secondary mt-3" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default CustomModal2;
