import React from 'react'
import { Header } from '../Components/Header'

import { Footer } from '../Components/Footer'
import WorkshopsComonent from './WorkshopsComonent'

function WorkshopsPage() {
  return (<>
  
    <div className='container'>

      <Header/>
      <WorkshopsComonent/>
      
    </div>
    <Footer/>
 </> )
}

export default WorkshopsPage