
import Admin from "./Pages/Admin";
import Login from "./Pages/Login";
import Manage from "./Pages/Manage";
import Register from "./Pages/Register";
import Choose from "./Pages/Choose";
import Speaker from "./Pages/Speaker";
import Attendee from "./Pages/Attendee";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {UserAuthContextProvider} from './context/userContext'
import AuthRoute from "./Pages/AuthRoute";
import AdminRoute from "./Pages/AdminRoute";
import ProtectedRoute from "./Pages/ProtectedRoute";
import Sarc3 from "./Pages/Sarc3";
import Home from "./Pages/Home";
import Forgot from "./Pages/Forgot";
import Profile from "./Pages/Profile";
import WorkshopsPage from "./Pages/WorkshopsPage";
import MainConference from "./Pages/MainConference";
import { toast, ToastContainer } from "react-toastify";
import Partner1 from "./Pages/Parnter1";
import Analsyis from "./Pages/Analsyis";
import ManageApps from "./Pages/ManageApps";
import AttendeeEdit from "./Pages/attendeeEdit";
import Campaign from "./Pages/Campaign";
import Zoom from "./Pages/Zoom";
import AllUsers from "./Pages/AllUsers";
import MyCertificate from "./Pages/MyCertificate";
import ABA from "./Pages/ABA";
import Safeguarding from "./Pages/Safeguarding";
import Psychopharmacology from "./Pages/Psychopharmacology";
import EarlyIntervention from "./Pages/EarlyIntervention";
import ECT from "./Pages/ECT";
import Introduction from "./Pages/Introduction";
function App() {
  return (
    <BrowserRouter>
    <UserAuthContextProvider>
    <ToastContainer />
    <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/partner" element={<Partner1/>}/>
    <Route path="/admin/analsyis" element={<AdminRoute><Analsyis/></AdminRoute>}/>
    <Route path="/admin/users" element={<AdminRoute><AllUsers/></AdminRoute>}/>

    <Route path="/admin/manage" element={<AdminRoute><ManageApps/></AdminRoute>}/>

    <Route path="/home" element={<Home/>}/>
    <Route path="/zoom" element={<Zoom/>}/>
    <Route path="/workshops" element={<WorkshopsPage/>}/>
    <Route path="/MainConference" element={<MainConference/>}/>
    <Route path="/profile/:id" element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
    <Route path="/mainconference/Certificate/:id" element={<MyCertificate/>}/>
    <Route path="/workshop/ABA/:id" element={<ABA/>}/>
    <Route path="/workshop/ECT/:id" element={<ECT/>}/>
    <Route path="/workshop/Introduction/:id" element={<Introduction/>}/>
    <Route path="/workshop/Safeguarding/:id" element={<Safeguarding/>}/>
    <Route path="/workshop/Psychopharmacology/:id" element={<Psychopharmacology/>}/>
    <Route path="/workshop/EarlyIntervention/:id" element={<EarlyIntervention/>}/>
    <Route path="/abstract-submission" element={<Navigate to='/sarc3'/>}/>
    <Route path="/sarc3" element={<Sarc3/>}/>
    <Route path="/register" element={<AuthRoute><Register/> </AuthRoute>}/>
    <Route path="/login" element={<AuthRoute><Login/> </AuthRoute>}/>
    <Route path="/forgotPassword" element={<AuthRoute><Forgot/> </AuthRoute>}/>
    <Route path="/sarc3/dashboard" element={<AdminRoute><Admin phrase='Total of members requests' nameClass='phraseBig'/></AdminRoute>}/>
    <Route path="/sarc3/manage/:id" element={<AdminRoute><Manage phrase='Back' /></AdminRoute>}/>
    <Route path="/sarc3/registerApplication" element={<ProtectedRoute><Choose/></ProtectedRoute>}/>
    {/* <Route path="/sarc3/speaker" element={<ProtectedRoute><Speaker/></ProtectedRoute>}/> */}
    <Route path="/sarc3/attendee" element={<ProtectedRoute><Attendee/></ProtectedRoute>}/>
    <Route path="/sarc3/attendee/edit/:email" element={<AdminRoute><AttendeeEdit/></AdminRoute>}/>
    <Route path="/admin/campaign" element={<AdminRoute><Campaign/></AdminRoute>}/>

    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
  </UserAuthContextProvider>
  </BrowserRouter>
  );
}

export default App;
