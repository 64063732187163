import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userContext";
const AdminRoute = ({ children }) => {
  const { user,isAdmin} = useUserAuth();

  console.log("Check user role: ", user);
  if (user && isAdmin) {
      return children;
}
// else if(user && (!isAdmin || !accessAPIs.includes('Admins'))){
//   return <Navigate to="/cost-center" />;
// }
return <Navigate to="/" />;
};

export default AdminRoute;