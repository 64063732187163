import React, { useState } from 'react';
import '../css/customModal.css';
import axios from 'axios';
import { useUserAuth } from '../context/userContext';

function AddUserModal({ userModalOpen, onAddUser,handleOpenModal ,setUserModalOpen,fetchUsers}) {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [parc, setParc] = useState('');
    const [SCFHSNo, setSCFHSNo] = useState('');
    // const[userModalOpen,setUserModalOpen]=useState(false)
    const modalClassName = userModalOpen ? 'custom-modal show' : 'custom-modal';
    const { user } = useUserAuth(); // Get user from context

   

    const handleCloseModal = () => {
        setUserModalOpen(false);
        // Reset form fields
        setFullName('');
        setEmail('');
        setPassword('');
        setPhone('');
        setParc('');
        setSCFHSNo('');
    };

    // Function to handle adding a new user
    const handleAddUser = async () => {
        try {
            const response = await axios.post("https://sarcbackend-8f58e8101861.herokuapp.com/registerUser", {
                name: fullName,
                phone,
                email,
                parcNo: parc,
                password,
                SCFHSNo
            });
            console.log(response.data);
    
            // Check if the registration is successful (status code 200)
            if (response.status === 200) {
                // Show an alert
                window.alert('User added successfully!');
                
                // Close modal and refresh users
                handleCloseModal();
                fetchUsers();
            }else{
                window.alert('User  already exist!');

            }
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };
    
    

    return (
        <div className={modalClassName}>
            <div className="custom-modal-content">
                <span className="close-btn" onClick={handleCloseModal}>&times;</span>
                <div className="modal-body">
                    <div className="div-input">
                        <label htmlFor='fullName'>Full name*</label>
                        <input
                        dir='auto'
                            id="fullName"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            type="text"
                            className='form-control'
                        />
                    </div>
                    <div className="div-input">
                        <label htmlFor='email'>Email Address*</label>
                        <input
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className='form-control'
                        />
                    </div>
                    <div className="div-input">
                        <label htmlFor='password'>Password*</label>
                        <input
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type='password'
                            className='form-control'
                        />
                    </div>
                    <div className="div-input">
                        <label htmlFor='phone'>Phone number*</label>
                        <input
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                            className='form-control'
                        />
                    </div>
                    <div className="div-input">
                        <label htmlFor='parc'>Parc number</label>
                        <input
                            id="parc"
                            value={parc}
                            onChange={(e) => setParc(e.target.value)}
                            type="text"
                            className='form-control'
                        />
                    </div>
                    <div className="div-input">
                        <label htmlFor='SCFHSNo'>SCFHS number</label>
                        <input
                            id="SCFHSNo"
                            value={SCFHSNo}
                            onChange={(e) => setSCFHSNo(e.target.value)}
                            type="text"
                            className='form-control'
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-primary" onClick={handleAddUser}>Add User</button>
                    <button className="btn btn-secondary mt-3" onClick={handleCloseModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default AddUserModal;
