import React from 'react'

export const Themes = () => {
  return (
    <>
    <div className="themes">
      <div className="row">
        <div className="col-md-8">
          <h2>Approved themes</h2>
          <div className="row">
            <div className="col-md-5">
              <div className="theme">
                <p>-Across life span</p>
                <p>-Adolescences & Adults</p>
                <p>-Vocational training</p>
                <p>-Residential</p>
                <p>-Profound autism</p>
                <p>-Diagnostic dilemmas</p>
              </div>
            </div>
            <div className="col-md-7">
             <div className="theme">
             <p>-Women with ASD</p>
              <p>-Transition</p>
              <p>-Psychiatric comorbidities</p>
              <p>-Quality of life/ Family stresses</p>
              <p>-Educational intervention for people ASD</p>
              <p>-Access to care in peripheral areas & Telemedicine</p>
             </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="theme-img">
            <img src="/assets/theme.webp" alt='theme'/>
          </div>
        </div>
      </div>
     </div>
     <div className="structure">
      <div className="fill">
        <p>Structure</p>
      </div>
      <div className="all-sec">
          <div className="table-sec">
            <p className="first-p">Panels for papers presentations</p>
            <p className="second-p">9 Am TO 3 Pm for 3 days</p>
          </div>


        <div className="table-sec center-border">
            <p className="first-p">Workshops</p>
            <p className="second-p">3 Pm TO 5 Pm for 2 days only</p>
          </div>
        {/* <div className="table-sec">
            <p className="first-p">Poster presentations</p>
            <p className="second-p">3-5 pm for 2 days only</p>
          </div> */}
      </div>
     </div>
    </>
  )
}
