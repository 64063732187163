import React, { useState } from 'react';
import '../css/customModal.css';

function CustomModal({ isOpen, onClose, workshops }) {
    const modalClassName = isOpen ? 'custom-modal show' : 'custom-modal';

    return (
        <div className={modalClassName}>
            <div className="custom-modal-content">
                <span className="close-btn" onClick={onClose}>&times;</span>
                <div className="modal-body">
                    {workshops.map((workshop, index) => (
                        <div key={index}>
                          Title:  {workshop.title} --Time: {workshop.time}<br/>
                          Speaker: {workshop.speaker}
                        </div>
                    ))}
                </div>
                <button className="close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default CustomModal;
