import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/userContext';
import axios from 'axios'; 
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pdf from '../Pdf'
import Modal from '../Components/Modal';


const Manage = ({phrase}) => {
    const navigate = useNavigate();
    const {user} = useUserAuth();
    const [member,setMember] = useState({});
    const {id} = useParams();
    const [appStatus,setAppStatus] = useState('');
    const [isOpen,setIsOpen]=useState(false);


    useEffect(() =>{
        fetchMember();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const fetchMember =async()=>{
        axios.get('https://sarcbackend-8f58e8101861.herokuapp.com/oneMember',{
            headers:{
                "auth": user.accessToken,
                "memberId":id
            }
        }).then(res => {
            console.log(res,'res member');
            if(res.data){ 
                setMember(res.data);
                setAppStatus(res.data.AppStatus)

            }
            

        })
            .catch(error => console.log(error,'error'))
        }
        const changeStatus =async() =>{
            console.log(appStatus);
            axios.post('https://sarcbackend-8f58e8101861.herokuapp.com/changeStatus',{AppStatus:appStatus},{
                headers:{
                    "auth":user.accessToken,
                    "appId":id
                }
            }).then(res => {
                console.log(res,'res status');
                navigate('/sarc3/dashboard');
            })
            .catch(error => {
                console.log(error,'error status');
                console.log(error.response.status,'status');
                if(error.response.status === 453){
                    let refresh = window.location.href;
                    window.location.href = refresh;
                }
            })
        }
        // const handleDownload = (url, filename) => {
        //     axios.get(url, {
        //       responseType: 'blob',
        //     })
        //     .then((res) => {
        //         console.log(res.data,'res.data');
        //       fileDownload(res.data,filename)
        //     })
        //   }
    
  return (
    <div className='dashboard' style={{position:'relative'}}>
        <Modal open={isOpen}  onClose={() => setIsOpen(false)}>
        <i style={{display:'block',color:'#636C87',fontSize:'64px'}} className="fa-solid fa-circle-exclamation"></i>
      <h2 style={{color:"#010B38",fontSize:"24px",fontWeight:700,marginTop:'16px',marginBottom:'8px'}}>Do you want to save changes?</h2>
        <p style={{color:'rgba(1,11,56,.6)',fontSize:"14px",fontWeight:500,marginBottom:'24px'}}>last changes wil be lost, if you dont save them</p>
        <button className='save-btn'  onClick={() => changeStatus()}>Save</button>
        <button className='dont-btn'  onClick={() => setIsOpen(false)}>Don't Save</button>
        <button className='cancel-btn text-dark'  onClick={() => setIsOpen(false)}>Cancel</button>
      </Modal>
    <div className='fill'></div>
    <div className='title'>
    <div className='container'>
        <div className='group'>
            <div className='back' onClick={() => navigate('/sarc3/dashboard')}>
            <img src='/assets/arrow_back.svg' alt='back'/>
            <p>{phrase}</p>
            </div>
            <div className='cv-action-btns'>
                <button onClick={()=>setIsOpen(true)}>Save</button>
                {/* <button><img src='/assets/cloud.svg' alt='cloud'/><CSVLink data={data} filename={member.name}> Export as Excel sheet</CSVLink></button> */}
            </div>
        </div>
    </div>
    </div>
    <div className='container'>
    <div className='pdf-information'>
            <div className='pdf-part'>
                <div style={{display:'flex',alignItems:'center'}}>
                    <p className='name'>{member ? member.name : '---'}</p>
                    <span className={`user-status ${appStatus === 'Approved' ? 'approved' : appStatus === 'Rejected' ? 'reject' :''}`}>{appStatus? appStatus : 'in progress'}</span>
                </div>
                <p className='sentence'>asked to be a <span>speaking member .</span></p>
                <p className='change-st'>Update status</p>
                <div className='status-values'>
                    <p onClick={e => setAppStatus('In Progress')}>in progress</p>
                    <p onClick={e => setAppStatus('Approved')}>Approved</p>
                    <p onClick={e => setAppStatus('Rejected')}>rejected</p>
                </div>
                <div className='preview-part'>
                    <div className='cv-part'>
                    {member && member.cv? 
                        <Pdf url= {member.cv}/>
                    : 
                       <img src="/assets/cv.webp" alt='cv'/> 
                    } 
                        <p>{member && member.cv ? member.cv.split('%2F')[1].split('-')[0] :' Untitled file 4.2 M/b'}</p>
                        <div className='cv-btns'>
                            <Link to={member.cv} target='_blank'>Preview</Link>
                           {member && member.cv? <Link to={member.cv} id='pdf-download' target='_blank' download={member.cv.split('%2F')[1].split('?')[0]}>Download PDF</Link>:
                           <button>Download PDF</button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='info-part'>
                <div className='info'>
                    <p className='head'>Title</p>
                    <p className='value'>{member && member.title? member.title : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Full name</p>
                    <p className='value'>{member ? member.name : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Email address</p>
                    <p className='value'>{member? member.email : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Alternative Email</p>
                    <p className='value'>{member && member.alterEmail? member.alterEmail : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Phone number</p>
                    <p className='value'>{member ? member.phone : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Classfication</p>
                    <p className='value'>{member && member.classification? member.classification : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>RESEARCH CATEGORIES </p>
                    <p className='value'>{member && member.reserchCategories && member.reserchCategories.length > 0 ? 
                    member.reserchCategories.map((res,i) => <span>- {res}</span>)
                     : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Parc number</p>
                    <p className='value'>{member ? member.parcNo : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>SCFHS number</p>
                    <p className='value'>{member && member.SCFHSNo ? member.SCFHSNo : '---'}</p>
                </div>
            </div>
    </div>
    <div className='pdf-information'>
            <div className='pdf-part'>
                <div className='preview-part'>
                    <div className='cv-part'>
                    {member && member.photo? 
                        <img src= {member.photo}/>
                    : 
                       <img src="/assets/cv.webp" alt='cv'/> 
                    } 
                        <p>{member && member.photo ? member.photo.split('%2F')[1].split('-')[0] :' Untitled file 4.2 M/b'}</p>
                        <div className='cv-btns'>
                            <Link to={member.photo} target='_blank'>Preview</Link>
                           {member && member.photo? <Link to={member.photo} id='pdf-download' target='_blank' download={member.photo.split('%2F')[1].split('?')[0]}>Download File</Link>:
                           <button>Download File</button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='info-part'>
                <div className='info'>
                    <p className='head'>University / Hospital</p>
                    <p className='value'>{member && member.place? member.place : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Whatsapp Number</p>
                    <p className='value'>{member && member.whatsNo? member.whatsNo : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Social Media Account</p>
                    <p className='value'>{member && member.socialAccount ? member.socialAccount : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Author and Co-author Names</p>
                    <p className='value'>{member? member.authorName : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Paper Title</p>
                    <p className='value'>{member && member.paperTitle? member.paperTitle : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Suggestion of speakers to be invited</p>
                    <p className='value'>{member ? member.suggestion : '---'}</p>
                </div>
                <div className='info'>
                    <p className='head'>Biography of presenting author</p>
                    <p className='value'>{member && member.bio? member.bio : '---'}</p>
                </div>
            </div>
    </div>
        {/* <div className='abstract'>
            <p>ABSTRACT</p>
            <p>{member && member.abstract ? member.abstract : '---'}</p>
        </div> */}
        <div className='pdf-information'>
            <div className='pdf-part' style={{width:'100%'}}>
                <div className='preview-part'>
                    <div className='cv-part'>
                    {member && member.abstract? 
                        <Pdf url= {member.abstract}/>
                    : 
                       <img src="/assets/cv.webp" alt='cv'/> 
                    } 
                        <p>ABSTRACT {member && member.abstract ? member.abstract.split('%2F')[1].split('-')[0] :' Untitled file 4.2 M/b'}</p>
                        <div className='cv-btns'>
                            <Link to={member.abstract} target='_blank'>Preview</Link>
                           {member && member.abstract? <Link to={member.abstract} id='pdf-download' target='_blank' download={member.abstract.split('%2F')[1].split('?')[0]}>Download PDF</Link>:
                           <button>Download PDF</button>}
                        </div>
                    </div>
                </div>
            </div>
        
    </div>
    </div>
    <div className='foot-page'>
            <p>© 2023<span className='dark'>SA</span><span className='light'>RC</span>Admin All Rights Reserved</p>
        </div>
    </div>
  )
}

export default Manage