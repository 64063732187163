import React from 'react'
import { Header } from '../Components/Header'
import MainConferenceComponent from './MainConferenceComponent'
import { Footer } from '../Components/Footer'

export default function MainConference() {
  return (<>
    <div className='container'>
        <Header/>
        <MainConferenceComponent/>
    </div>
            <Footer/>
 </> )
}
