import React from 'react'
import { Header } from '../Components/Header'
import { Footer } from '../Components/Footer'
import { Contact } from '../Components/Contact'
import { Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/userContext'
import { getAuth, signOut } from 'firebase/auth';
import { useEffect } from 'react'
import '../css/home.css'
const Home = () => {
  const {setIsAdmin,isAdmin,user} = useUserAuth();
  // const user={
  //   "_id": "4t5BbooRpid7fQOD08nWxnUqvTq1",
  //   "email": "sam3nee@gmail.com",
  //   "phone": "+962798197697",
  //   name: "abedalaziz alezeizat",
  //   "parcNo": "",
  //   "SCFHSNo": "",
  //   "role": "user"

  // }
  const navigate =useNavigate();
  const auth = getAuth();
  async function handleLogout() {
    isAdmin &&  setIsAdmin(false);
    signOut(auth);
    window.location.href='/login'
    // navigate("/login");
}
useEffect(() =>{
  window.scroll(0,0);
    },[])
  return (
    <div className='home-back'>
      <div className='container '>
        <Header img='/assets/sarc.svg' smallNav={
          user? 
       <button className='logout-btn' onClick={() => handleLogout()}>Log Out</button>
         
          :
           <>
        <Link to='/login' className='login-btn'>Log In</Link>
        <button className='logout-btn' onClick={() => navigate('/register')}>Sign Up</button>
        </> 
        }>
        { user?
        //  <div className='register'> 
        
        //  <button onClick={() => handleLogout()}>Log Out2</button>
         
        //  </div>
        (<>
      


      {/* <div class="">
 
    <label for="profile2" class="profile-dropdown">
      <input type="checkbox" id="profile2"/>
      <img src="https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png"/>
      <span>{user&&user.email||user.name}</span>
      <label for="profile2" class="dropdown-arrow"><i class="mdi mdi-menu"></i></label>
      <div>{user.id}</div>
      <ul>
        <li><a href={`/profile/${user.uid}`} ><i class="mdi mdi-account"></i>Profile</a></li>
        <li><a href="#"><i class="mdi mdi-settings"></i>Settings</a></li>
        <li onClick={() => handleLogout()}><a href="#"><i class="mdi mdi-logout"></i>Logout</a></li>
      </ul>
    </label>
  </div>
 */}

          
          
          
          
          
          
          <div class="background"></div></>)
        :
   
     <div className=''>
      <div className='row'>
        <div className='col'>
        <button className='login-btn'  onClick={() => navigate('/login')}>Log In</button>
     
        </div>
        <div className='col'>
        <button className='logout-btn' onClick={() => navigate('/register')}>Sign Up</button>

        </div>
      </div>
     </div>
   }
        </Header>
      <div className='welcome'>
        <div className='img'>
          <img src='/assets/auth-sarc.webp' alt='auth'/>
        </div>
        <div className='sentence'>
          <p>Welcome to the Saudi Autism Research and Conference and Applied Behavioral Analysis! This is the largest annual gathering of autism researchers in Saudi Arabia.</p>
        </div>
      </div>
      </div>
      <div className='welcome-img'>
        <div className='overlay-image'></div>
        <div className='img-1 img-group'>
          <img  src='/assets/num1.png' alt='num1'/>
          <img  src='/assets/num1.png' alt='num1'/> 
        </div>
        <div className='img-2 img-group'>
          <img  src='/assets/num2.png' alt='num1'/>
          <img  src='/assets/num2.png' alt='num1'/> 
        </div>
        <div className='img-3 img-group'>
          <img  src='/assets/num3.png' alt='num1'/>
          <img  src='/assets/num3.png' alt='num1'/> 
        </div>
        <div className='img-4 img-group'>
          <img  src='/assets/num4.png' alt='num1'/>
          <img  src='/assets/num4.png' alt='num1'/> 
        </div>
      </div>
      <div className='container'>
        <div className='confrence'>
          <div className='img'>
            <h2>About Confrence</h2>
            <img src='/assets/confrence.svg' alt='confrence'/>
          </div>
          <div className='parag'>
            <p>
            The conference disseminates the latest research on autism spectrum disorder (ASD) and encourages scientists to share their newest research findings and innovations.Autism is a neurodevelopmental disorder that is characterized by challenges in social interaction, communication, and repetitive behaviors. It is estimated that 1 in 68 children in the world is affected by ASD. Although autism is common in Saudi Arabia and the Arab Gulf region, most of what is known about the disorder is based on western data. Research in our region is very weak and limited. This is why the Saudi Autism Research and Conference and Applied Behavioral Analysis (SARC&ABA) is so important. SARC&ABA is a forum for researchers to share their work on ASD. It is also a place for families and professionals to learn about the latest research and treatment options. The conference will feature keynote speakers, oral presentations, poster presentations, and workshops. The keynote speakers will be leading experts in the field of ASD research. The poster presentations will be given by students and early-career researchers. The workshops will be offered on a variety of topics related to ASD research and treatment. SARC&ABA is open to researchers, families, and professionals from all over the world. Registration is now open. 
            </p>
            <p>We hope to see you there!</p>
          </div>
        </div>
        <div className='objs'>
          <h2>Objectives</h2>
          <ul>
          <li>To provide up-to-date information on ASD research to researchers, clinicians, and families.</li>
          <li>To facilitate networking and collaboration between researchers.</li>
          <li>To promote the exchange of ideas and best practices for ASD research.</li>
          <li>To raise awareness of ASD and its impact on individuals, families, and society.</li>
          <li>To advocate for the rights of people with ASD.</li>
          <li>The conference will achieve these goals and objectives by providing a platform for researchers to present their work, networking opportunities for researchers, and educational sessions for families and professionals. The conference will also feature keynote speakers who are leading experts in the field of ASD research.</li>
          <li>The conference is a valuable opportunity for researchers, families, and professionals to learn about the latest advances in ASD research and treatment. It is also a great opportunity to network with other people who are passionate about making a difference in the lives of people with ASD.</li>
   </ul>
        </div>
        <div className='objs'>
        <h2>Goals</h2>
          <ol>
            <li>Provide a venue to present research projects in the field of autism.</li>
            <li>Build a research methodology, analysis, presentation, and discussion forum to improve national and regional autism research outcomes.</li>
            <li>Encourage young researchers to present and embrace more scientific research.</li>
            <li>Serve as a venue for the autism research community.</li>
            <li>Build national and regional databases about autism and related disorders.</li>
            <li>To provide a forum for researchers to share their latest research findings and innovations on autism spectrum disorder (ASD).</li>
            <li>To promote collaboration between researchers from different disciplines.</li>
            <li>To raise awareness of ASD and its impact on individuals, families, and society.</li>
            <li>To advocate for the rights of people with ASD.</li>
          </ol>
        </div>
        <hr className='line-hr'/>
        <div className='confrences-imgs'>
          <h2 className='conf-title'>Conferences </h2>
          <div className='sections-conf'>
          <div className='confrence-sec'>
            <div className='img'><img src='/assets/sarc1.webp' alt='sarc1'/></div>
            <h2 style={{color: "#3DBD4A"}}>SA<span style={{color: "#303030"}}>RC</span><span style={{color: "#C94800"}}>  1</span></h2>
          </div>
          <div className='confrence-sec'>
            <div className='img'><img src='/assets/sarc2.webp' alt='sarc2'/></div>
            <h2 style={{color: "#322C27"}}>SA<span style={{color: "#E61D32"}}>RC</span><span style={{color: "#178791"}}>  2</span></h2>
          </div>
          <div className='confrence-sec'>
            <Link to={'/sarc3'}>
            <div className='img'><img src='/assets/sarc33.webp' alt='sarc3'/></div>
            <h2 style={{color: "#1081A3"}}>SA<span style={{color: "#C1D34C"}}>RC</span><span style={{color: "#594D49"}}>3</span></h2>
            </Link>
          </div>
          </div>
        </div>
        <div className='benefit'>
          <div className='img'>
            <img src='/assets/benefit.webp' alt='benefit'/>
          </div>
          <div className='parag'>
            <h2>What’s the benefit of attending sarc&ABA?</h2>
            <p>Learn about the latest advances in ASD research
              Network with other researchers
              Discuss your own work with other researchers
              Learn about the latest treatment options for ASD
              Get involved in the autism research community</p>
          </div>
        </div>
        <div className='more-info'>
          <h2>Why SARC&ABA is important?</h2>
          <p>SARC&ABA is important because it is the only conference in Saudi Arabia that focuses on ASD research. The conference provides a platform for researchers to share their work and to network with other researchers. It also provides an opportunity for families and professionals to learn about the latest research and treatment options.
          SARC&ABA is also important because it raises awareness of ASD in Saudi Arabia. The conference helps to break down the stigma associated with ASD and to promote understanding of the disorder.</p>
        </div>
        <hr className='line-hr'/>
        <div className='more-info'>
          <h2>What can I expect at SARC&ABA?</h2>
          <p>SARC&ABA will feature keynote speakers, oral presentations, poster presentations, and workshops. The keynote speakers will be leading experts in the field of ASD research. The oral presentations will be given by researchers from all over the world. The poster presentations will be given by students and early-career researchers. The workshops will be offered on a variety of topics related to ASD research and treatment.</p>
        </div>
        <hr className='line-hr'/>
        <div className='more-info'>
          <h2>Who should attend SARC&ABA?</h2>
          <p>SARC&ABA is open to researchers, families, and professionals from all over the world. If you are interested in ASD research, then you should attend SARC&ABA. The conference is also a great opportunity for families and professionals to learn about the latest research and treatment options.</p>
        </div>
        <hr className='line-hr'/>
        <div className='more-info'>
          <h2>How can I register for SARC&ABA?</h2>
          <p>Registration for SARC&ABA is now open. You can register online at the SARC&ABA website.</p>
        </div>
        <hr className='line-hr'/>
        <Contact/>
      </div>
      <Footer/>
    </div>
  )
}

export default Home