import React, { useState } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';


//PDFjs worker from an external cdn
// const url =
// "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"

export default function Pdf({url}) {
	
	pdfjs.GlobalWorkerOptions.workerSrc =
	`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
	setNumPages(numPages);
	setPageNumber(1);
}
return (
	<div style={{width:'156px',height:'218px',boxShadow:'0px 4px 4px 0px rgba(0,0,0,25%)'}}>
	<Document
		file={url}
		onLoadSuccess={onDocumentLoadSuccess}
		>
		<Page pageNumber={pageNumber} />
	</Document>
	</div>
);
}
